export enum Configuration {
  development = 'development',
  production = 'production',
}

/**
 * @returns The {@link Configuration} that corresponds to the environment set at
 * build time. Defaults to {@link Configuration.development}.
 */
export function getCurrentConfiguration(): Configuration {
  if (process.env.CONFIGURATION === 'production') {
    return Configuration.production;
  } else {
    return Configuration.development;
  }
}
