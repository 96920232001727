/* POSTs form data to ActionNetwork. Also POSTs to
 * MobileCommons if relevant.
 *
 * Matches format expected by IWV pipeline:
 * https://github.com/democrats/airflow-config/blob/main/sql/pipelines/i_will_vote/iwv_base.sql
 */

import { Jurisdiction, State } from '../data/jurisdictions';
import { Configuration } from '../utils/configuration';

const formUuids: Forms = {
  register: {
    // https://actionnetwork.org/forms/iwv-staging-register-to-vote/
    development: '132bbd21-adff-43d2-b107-6fe6f4650ef0',

    // https://actionnetwork.org/forms/iwv-production-register-to-vote/
    production: '53200fc3-b99f-49f8-980d-ada821a1aec8',
  },

  voteByMail: {
    // https://actionnetwork.org/forms/vote-by-mail-contact-capture-staging/
    development: 'a649761f-d2aa-49ea-a709-50c4c723f88b',

    // https://actionnetwork.org/forms/iwv-vote-by-mail-production/
    production: '619714dd-cbe0-454d-85e0-d50db51cd92e',
  },

  inPerson: {
    // https://actionnetwork.org/forms/learn-more-about-voting-in-person-staging
    development: 'ea606ccb-b777-4f0e-8e4f-d6b9f00ba209',

    // https://actionnetwork.org/forms/learn-more-about-voting-in-person-production
    production: 'bdd41bf5-4977-42e0-8016-280a62536ee7',
  },
};

const mobileCommonsUuids: Forms = {
  // TODO: replace development values with non-prod uuids
  register: {
    development: '',
    production: 'OP503A00769826CBA1BE4B1E949BE57245',
  },
  voteByMail: {
    development: '',
    production: 'OP3475621925AD57304B5B7622E6C20574',
  },
  inPerson: {
    development: 'OP0341BA14BB7CAF10B5E1BA5C0178DA70', // production value for testing
    production: 'OP0341BA14BB7CAF10B5E1BA5C0178DA70',
  },
};

export type UTMFields = {
  partnerId: string;
  partnerCampaign: string;
  partnerContent: string;
  partnerCustom: string;
  partnerMedium: string;
  partnerTerm: string;
  appIntent?: string;
};

export type ContactCaptureFields = {
  firstName: string;
  lastName: string;
  email: string;
  postalCode: string;
  cellphone: string;
  prefersEmail?: boolean;
  prefersText?: boolean;
  prefersPhone?: boolean;
};

export type ActionNetworkFormFields = {
  jurisdiction: State;
} & ContactCaptureFields;

export type ActionCode = 'completed_contact_capture' | 'sent_sos' | 'sent_nvra';

export enum FormType {
  register = 'register',
  voteByMail = 'voteByMail',
  inPerson = 'inPerson',
}

type Forms = Record<FormType, Record<Configuration, string>>;

export interface FormService {
  sendToActionNetwork(
    which: FormType,
    fields: ActionNetworkFormFields,
    utm: UTMFields,
    actionCode: ActionCode
  ): Promise<void> | void;
}

export class FormServiceImpl implements FormService {
  constructor(private readonly env: Configuration) {}

  async sendToActionNetwork(
    which: FormType,
    fields: ActionNetworkFormFields,
    utm: UTMFields,
    actionCode: ActionCode
  ): Promise<void> {
    const uuid = formUuids[which][this.env];

    // `background_request=true` allows requests to be processed by an
    // asynchronous queue. Action Network returns an empty success response:
    // requests are neither authenticated nor validated in real time.
    // https://democrats.atlassian.net/browse/TECH-13311
    const url = `https://actionnetwork.org/api/v2/forms/${uuid}/submissions?background_request=true`;

    // see https://actionnetwork.org/docs/v2/record_submission_helper
    const data = {
      person: {
        ...(fields.firstName && { given_name: fields.firstName }),
        ...(fields.lastName && { family_name: fields.lastName }),
        email_addresses: [
          {
            address: fields.email,
            status: 'subscribed',
          },
        ],
        postal_addresses: [
          {
            ...(fields.postalCode && { postal_code: fields.postalCode }),
            ...(fields.jurisdiction && { region: fields.jurisdiction }),
            country: 'US',
          },
        ],
        custom_fields: {
          // UTM fields
          ...(utm.partnerId && {
            PartnerID: utm.partnerId,
          }),
          ...(utm.partnerCampaign && {
            PartnerCampaign: utm.partnerCampaign,
          }),
          ...(utm.partnerContent && {
            PartnerContent: utm.partnerContent,
          }),
          ...(utm.partnerCustom && {
            PartnerCustom: utm.partnerCustom,
          }),
          ...(utm.partnerMedium && {
            PartnerMedium: utm.partnerMedium,
          }),
          ...(utm.partnerTerm && {
            PartnerTerm: utm.partnerTerm,
          }),
          // Other custom fields
          ...(fields.firstName && {
            first_name: fields.firstName,
          }),
          ...(fields.lastName && {
            last_name: fields.lastName,
          }),
          ...(fields.cellphone && {
            'Mobile-Phone': fields.cellphone,
          }),
          // Assumes opt-in unless prefersText is explicitly set
          ...(fields.cellphone &&
            fields.prefersText === undefined && {
              'SMS Opt-In': true,
            }),
          ...(fields.email && {
            email: fields.email,
          }),
          ...(fields.jurisdiction && { State: fields.jurisdiction }),
          ...(fields.postalCode && { zip_code: fields.postalCode }),
          ...(actionCode && { action_code: actionCode }),
          // Only include preference field if defined (default is undefined, not false)
          ...(fields.prefersPhone !== undefined && {
            prefers_phone: fields.prefersPhone,
          }),
          ...(fields.prefersEmail !== undefined && {
            prefers_email: fields.prefersEmail,
          }),
          ...(fields.prefersText !== undefined && {
            prefers_text: fields.prefersText,
          }),
        },
      },
    };

    // TODO(fiona): Add timeouts to these request similar to VisService.
    if (fields.cellphone) {
      // Send to mobile commons if prefersText is undefined or true
      if (fields.prefersText !== false) {
        await this.sendToMobileCommons(which, fields);
      }
    }

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  private async sendToMobileCommons(
    which: FormType,
    fields: ActionNetworkFormFields
  ): Promise<void> {
    const url = 'https://secure.mcommons.com/profiles/join';
    // Mobile Commons does not accept POST'd json
    // 'fetch' automatically adds correct headers for FormData
    const formData = new FormData();
    formData.append('person[phone]', fields.cellphone);
    formData.append('opt_in_path[]', mobileCommonsUuids[which][this.env]);
    formData.append('person[first_name]', fields.firstName || '');
    formData.append('person[last_name]', fields.lastName || '');
    formData.append('person[email]', fields.email || '');
    formData.append('person[postal_code]', fields.postalCode || '');

    await fetch(url, {
      method: 'POST',
      body: formData,
    });
  }
}

// Fields for the Action Kit Contact Form
export type ActionKitContactCaptureFields = {
  firstName: string;
  lastName: string;
  email: string;
  jurisdiction: Jurisdiction;
  postalCode: string;
  cellphone: string;
  votingPlan: string;
};
