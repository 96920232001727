import { QueryClientProvider } from '@tanstack/react-query';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { getCurrentConfiguration } from '../baseline/utils';
import { makeRoutes } from '../components/routes';
import { Services } from '../services/services';

// For Tailwind. Comes first due to reset.
import '../styles/app.css';

// Existing, pre-Tailwind SASS styles.
import '../scss/main.scss';

const startApp = async () => {
  const root = createRoot(document.querySelector('#root')!);

  if (process.env.NODE_ENV === 'development') {
    const { worker } = await import('../msw/browser');
    await worker.start({
      // We want most of the app’s requests to happen without being intercepted,
      // so this suppresses the warnings when they happen.
      onUnhandledRequest: 'bypass',
    });
  }

  const services = new Services();
  const router = createBrowserRouter(
    makeRoutes(getCurrentConfiguration(), services)
  );

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={services.queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </React.StrictMode>
  );
};

startApp();
