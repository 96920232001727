import * as qs from 'query-string';

import { Jurisdiction, Jurisdictions, State } from '../data';
import {
  Option,
  SiteType,
  VISBaseLocation,
  VISEarlyVoteLocation,
  VISDropOffLocation,
  VISLocation,
  VISVoterAddress,
  VISPrecinctResult,
  VoterAddress,
  PrecinctResult,
  VotingLocation,
  VISElectionDayLocation,
  VISJurisdictionLocationSummary,
  LocationSummaryByStateCode,
} from '../utils';

import { AnalyticsService } from './analytics-service';

// https://github.com/democrats/voting-information-service/blob/main/RESPONSE_CODES.md
export const VISErrorCodeIDs = [
  'INVALID_RESULTS_PER_TYPE',
  'NO_VOTING_LOCATIONS_AVAILABLE',
  'NO_PRECINCT_FOUND',
  'NO_VOTING_LOCATIONS_FOUND',
  'NO_VOTER_ADDRESS_PROVIDED',
  'INCOMPLETE_VOTER_ADDRESS',
  'SERVICE_UNAVAILABLE',
  'VOTER_ADDRESS_ERROR',
  'NORMALIZATION_INCONCLUSIVE',
  'USADDRESS_PARSING_FAILED',
] as const;

export type VISErrorCode = (typeof VISErrorCodeIDs)[number];

export type LookupErrorCode = VISErrorCode | 'DEFAULT' | 'FETCH_FAILED';

/**
 * JSON response from VIS’s `/api/locate` endpoint.
 *
 * @see https://github.com/democrats/voting-information-service
 */
export type VISVotingLocationsResponse =
  | {
      lookup_status: 'SUCCESS';
      voter_address: VISVoterAddress;
      precinct: VISPrecinctResult;
      early_vote_locations: VISEarlyVoteLocation[];
      polling_locations: VISBaseLocation[];
      drop_off_locations: VISDropOffLocation[];
    }
  | {
      lookup_status?: 'NO_VOTING_LOCATIONS_FOUND';
      error_code: VISErrorCode;
      detail?: string | null;
      voter_address?: VISVoterAddress;
      precinct?: VISPrecinctResult;
      early_vote_locations?: VISEarlyVoteLocation[];
      polling_locations?: VISBaseLocation[];
      drop_off_locations?: VISDropOffLocation[];
    };

export type VISVotingLocationsSuccess = {
  status: 'SUCCESS';
  voterAddress: VoterAddress;
  precinct: PrecinctResult;
  earlyVoteLocations: VotingLocation[];
  electionDayLocations: VotingLocation[];
  dropoffLocations: VotingLocation[];
};

export type VISVotingLocationsError = {
  status: 'ERROR';
  // We return the voterAddress as well since we can use it to switch
  // jurisdictions even in the case of errors.
  voterAddress: Option<VoterAddress>;
  errorCode: LookupErrorCode;
  detailInternal: string | null | undefined;
};

/**
 * Cleaned-up version of {@link VISVotingLocationsResponse} that
 * {@link VISService} returns.
 */
export type VISVotingLocations =
  | VISVotingLocationsSuccess
  | VISVotingLocationsError;

export type GeoInfo = {
  country_name: string;
  region_code: string;
};

export type GeoIpService = {
  getGeoInfo(): Promise<Option<GeoInfo>>;
};

export type InputAddress = {
  addressLine1?: string;
  zipCode?: string;
  rawString?: string;
  clientId?: Option<string>;
  organization?: Option<string>;
};

export type VISLocationSummaryResponse = {
  [key in State]?: VISJurisdictionLocationSummary;
};

export type VISLocationSummaryError = {
  [key in State]?: undefined;
};

const parseVotingLocations = (
  locations: Option<VISLocation[]>,
  siteType: SiteType
): VotingLocation[] =>
  (locations || []).map((location: VISLocation) => {
    const parsedLocation = {
      locationId: location.location_id,
      locationName: location.location_name,
      addressLine1: location.address_line_1,
      addressLine2: location.address_line_2,
      city: location.city,
      stateCode: location.state_code,
      zip: location.zip,
      lat: location.lat ? location.lat : undefined,
      lon: location.lon ? location.lon : undefined,
      datesHours: location.dates_hours,
      locationNotes: location.location_notes,
      scheduleExceptions: location.schedule_exceptions,
      features: location.features,
    };

    switch (siteType) {
      case 'dropoffLocations': {
        const dropoffLocation = location as VISDropOffLocation;

        return {
          ...parsedLocation,
          openEarlyVoting: dropoffLocation.open_early_voting,
          openElectionDay: dropoffLocation.open_election_day,
          schedule: dropoffLocation.schedule,
          siteType,
        };
      }

      case 'earlyVoteLocations': {
        const earlyVoteLocation = location as VISEarlyVoteLocation;

        return {
          ...parsedLocation,
          schedule: earlyVoteLocation.schedule,
          siteType,
        };
      }
      case 'electionDayLocations': {
        const electionDayLocation = location as VISElectionDayLocation;

        return {
          ...parsedLocation,
          schedule: electionDayLocation.schedule,
          siteType,
        };
      }
    }
  });

const parsePrecinct = (visPrecinct: VISPrecinctResult): PrecinctResult => {
  return {
    vanPrecinctId: visPrecinct.van_precinct_id,
    dncPrecinctId: visPrecinct.dnc_precinct_id,
    dncPrecinctIdRatio: visPrecinct.dnc_precinct_id_ratio,
    precinctDerivationMethod: visPrecinct.precinct_derivation_method,
  };
};

const parseVoterAddress = (visAddress: VISVoterAddress): VoterAddress => {
  let normalizedAddress: VoterAddress['normalizedAddress'] = undefined;

  if (visAddress.normalized_address) {
    normalizedAddress = {
      addressLine1: visAddress.normalized_address.address_line_1,
      addressLine2: visAddress.normalized_address.address_line_2,
      city: visAddress.normalized_address.city,
      stateCode: visAddress.normalized_address.state_code,
      zip: visAddress.normalized_address.zip,
      zip4: visAddress.normalized_address.zip4,
      latitude: visAddress.normalized_address.latitude,
      longitude: visAddress.normalized_address.longitude,
      countyName: visAddress.normalized_address.county_name ?? null,
    };
  }

  return {
    normalizedAddress,
  };
};

/**
 * Converts the not-as-friendly VISVotingLocationsResponse from the VIS JSON API
 * to the more pleasant VISVotingLocations.
 */
export const parseLocationResponse = (
  response:
    | VISVotingLocationsResponse
    | {
        error_code: 'FETCH_FAILED';
        detail: string;
        lookup_status?: undefined;
        voter_address?: undefined;
      }
): VISVotingLocations => {
  if (response.lookup_status === 'SUCCESS') {
    return {
      status: 'SUCCESS',

      voterAddress: parseVoterAddress(response.voter_address),
      precinct: parsePrecinct(response.precinct),

      earlyVoteLocations: parseVotingLocations(
        response.early_vote_locations,
        'earlyVoteLocations'
      ),

      electionDayLocations: parseVotingLocations(
        response.polling_locations,
        'electionDayLocations'
      ),

      dropoffLocations: parseVotingLocations(
        response.drop_off_locations,
        'dropoffLocations'
      ),
    };
  } else {
    return {
      status: 'ERROR',
      errorCode: response.error_code as LookupErrorCode,
      // We don’t want to show this to users because it’s not localized but it’s
      // handy to have around for debugging.
      detailInternal: response.detail,
      // Still provided in case we can determine a jurisdiction.
      voterAddress:
        response.voter_address && parseVoterAddress(response.voter_address),
    };
  }
};

export const parseLocationSummaryResponse = (
  response: VISLocationSummaryResponse
): LocationSummaryByStateCode => {
  const finalSummaryResponse: LocationSummaryByStateCode = {};
  const responseEntries = Object.entries(response);
  for (const [stateCode, jurisdictionSummary] of responseEntries) {
    if (Jurisdictions.isState(stateCode)) {
      finalSummaryResponse[stateCode] = {
        electionDate:
          jurisdictionSummary.CurrentElection?.election_date ?? null,
        eDayLocationCount: jurisdictionSummary.PollingLocation,
        dropOffLocationCount: jurisdictionSummary.DropOffLocation,
        evLocationCount: jurisdictionSummary.EarlyVoteLocation,
      };
    }
  }
  return finalSummaryResponse;
};

type FetchJsonOptions = {
  timeoutMs: number;
};

/**
 * Service to access endpoints provided by VIS for address lookup and
 * geolocation.
 *
 * @see https://github.com/democrats/voting-information-service
 */
export class VISService implements GeoIpService {
  static readonly ANALYTICS_CATEGORY = 'VIS Service';

  constructor(
    private readonly analytics: AnalyticsService,
    private readonly apiKey = process.env.VIS_KEY as string,
    private readonly baseUrl = process.env.VIS_BASE_URL as string
  ) {}

  /**
   * Makes a fetch to the VIS Service with the given path.
   *
   * Also sends along timing information to Google Analytics.
   *
   * @param path Path on the VIS service, starting with a leading slash
   * @returns Parsed JSON from VIS
   */
  private async visFetchJson<T>(
    path: string,
    { timeoutMs }: FetchJsonOptions
  ): Promise<T> {
    // Removes the leading "/" or "/api/" from the path and doesn’t include any
    // parameters (anything after a "?").
    const analyticsLabel = /^\/(api\/)?([^?]*)/.exec(path)?.[2] ?? '';

    // See: https://github.com/whatwg/fetch/issues/951#issuecomment-541369940
    const controller = new AbortController();
    // TODO(fiona): Could make this take a TimeoutError DOMException as the
    // reason (rather than the default AbortError) to match WHATWG:
    // https://github.com/whatwg/dom/pull/1032
    //
    // (TypeScript definitions in this project as of 3/28/22 don’t support the
    // reason parameter to abort, though, so not doing it now.)
    setTimeout(() => controller.abort(), timeoutMs);

    const init: RequestInit = {
      headers: {
        'X-API-Key': this.apiKey,
      },
      signal: controller.signal,
    };

    try {
      // Commenting out this event due to exceeding our GA quota:
      // https://democrats.atlassian.net/browse/VS-1280
      // TODO: Investigate re-instituting this code after the election.
      // return await timedAwait(
      //   (await fetch(`${this.baseUrl}${path}`, init)).json(),
      //   (ms) => {
      //     // We currently only track the timing of successes.
      //     this.analytics.timed({
      //       category: VISService.ANALYTICS_CATEGORY,
      //       label: analyticsLabel,
      //       variable: 'load',
      //       value: ms,
      //     });
      //   }
      // );
      return await (await fetch(`${this.baseUrl}${path}`, init)).json();
    } catch (error: any) {
      // This was either our AbortController going off or the user cancelling
      // the fetch in some other way.
      if (error?.name === 'AbortError') {
        this.analytics.event({
          category: VISService.ANALYTICS_CATEGORY,
          label: analyticsLabel,
          action: 'load_abort',
        });
      }

      throw error;
    }
  }

  /**
   * Uses the client IP to geolocate them to a particular country and region
   * (state).
   *
   * Returns undefined if unable to do so or if the request times out.
   */
  async getGeoInfo(): Promise<Option<GeoInfo>> {
    try {
      // Short timeout because this is blocking initial page render as we try to
      // geo-locate the user to a state.
      return await this.visFetchJson('/geo-ip', { timeoutMs: 5_000 });
    } catch (error) {
      console.error(`Error loading geoip info: ${error}`);

      return undefined;
    }
  }

  // @visible-for-testing
  async fetchVotingLocations({
    address,
    clientId,
    organization,
    state,
    resultsPerType,
  }: {
    address: string;
    clientId: string;
    organization?: Option<string>;
    state: Option<Jurisdiction>;
    resultsPerType: number;
  }): Promise<
    | VISVotingLocationsResponse
    | {
        error_code: 'FETCH_FAILED';
        detail: string;
      }
  > {
    const params = {
      address,
      results_per_type: resultsPerType,
      client_id: clientId,
      organization: organization,
      // HACK(fiona): VIS doesn’t use state, we include it so that the mock
      // location finder can return results in the same state.
      state,
    };

    const votingLocationLookupPath = `/api/locate?${qs.stringify(params)}`;

    try {
      return await this.visFetchJson(votingLocationLookupPath, {
        // TODO(fiona): Lower this if we generally are able to resolve these
        // faster.
        timeoutMs: 30_000,
      });
    } catch (error) {
      return {
        // Must match 'error_code' format returned by VIS
        error_code: 'FETCH_FAILED',
        detail: 'JS error fetching voting locations',
      };
    }
  }

  // @visible-for-testing
  async fetchLocationsSummary(
    clientId: string
  ): Promise<VISLocationSummaryResponse> {
    const params = {
      client_id: clientId,
    };

    const nationalSummaryPath = `/api/data_summary/national/locations?${qs.stringify(params)}`;

    try {
      return await this.visFetchJson(nationalSummaryPath, {
        // TODO(fiona): Lower this if we generally are able to resolve these
        // faster.
        timeoutMs: 30_000,
      });
    } catch (error) {
      console.error(`Error loading location summary info: ${error}`);
      throw error;
    }
  }

  /**
   * Gets voting locations from VIS and parses the responses into our JS data
   * structures.
   */
  async lookUpVotingLocations({
    address,
    state,
    clientId,
    organization,
    resultsPerType,
  }: {
    address: string;
    state: Option<Jurisdiction>;
    clientId: string;
    organization?: Option<string>;
    resultsPerType: number;
  }): Promise<VISVotingLocations> {
    return parseLocationResponse(
      await this.fetchVotingLocations({
        address,
        clientId,
        organization,
        state,
        resultsPerType,
      })
    );
  }

  /**
   * Gets state-by-state counts of locations loaded in VIS (and election day date)
   * and parses the response into our JS data structure.
   */
  async getNationalVotingLocationsSummary(clientId: string) {
    return parseLocationSummaryResponse(
      await this.fetchLocationsSummary(clientId)
    );
  }
}

export type VISProvider = {
  vis: VISService;
};
