import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { VepApiActiveLocalizedNullableString } from '@dnc/baseline';
import { LocaleContext } from '@dnc/shared-components';

import {
  VepApiImportantDatesAndDeadlines,
  VepApiRegistrationOptions,
} from '../../../baseline/ve-papi/types';

import {
  VoterEdDateDeadline,
  VoterEdNote,
  VoterEdUrl,
} from './widget-component-utils';

type WidgetRegistrationSectionProps = {
  importantDatesAndDeadlines: VepApiImportantDatesAndDeadlines | null;
  voterRegistrationInfo: VepApiRegistrationOptions;
  checkRegistrationUrl: VepApiActiveLocalizedNullableString;
};

export const WidgetRegistrationSection: React.FC<
  WidgetRegistrationSectionProps
> = ({
  importantDatesAndDeadlines,
  voterRegistrationInfo,
  checkRegistrationUrl,
}) => {
  const {
    electionDay,
    registerInPersonBy,
    registerInPersonByNoteHtml,
    registerOnlineBy,
    registerOnlineByNoteHtml,
    registerPostmarkBy,
    registerPostmarkByNoteHtml,
    registerSameDayNoteHtml,
  } = importantDatesAndDeadlines ?? {};
  const { online, mail, inPerson } = voterRegistrationInfo ?? {};
  const locale = React.useContext(LocaleContext);

  return (
    <div className="widget-voter-education__components">
      {checkRegistrationUrl && (
        <>
          <h3>
            <FormattedMessage
              defaultMessage="Voter Registration Status"
              id="GiwkQF"
            />
          </h3>
          <VoterEdUrl
            urlString={checkRegistrationUrl}
            locale={locale}
            formattedMessage={
              <FormattedMessage
                defaultMessage="Check your voter registration status"
                id="mh6iQm"
              />
            }
          />
        </>
      )}
      {online &&
        (online.copyHtml ??
          online.url ??
          online.urlCtaText ??
          online.alternateUrl ??
          online.alternateUrlCtaText ??
          registerOnlineBy ??
          registerOnlineByNoteHtml) && (
          <>
            <h3>
              <FormattedMessage defaultMessage="Register Online" id="PTM+LJ" />
            </h3>

            {online.copyHtml && (
              <VoterEdNote note={online.copyHtml} locale={locale} />
            )}

            {registerOnlineBy && (
              <VoterEdDateDeadline
                locale={locale}
                dateString={registerOnlineBy}
                textPrompt={
                  <FormattedMessage
                    defaultMessage="Register online by: "
                    id="1nbgqp"
                  />
                }
                note={registerOnlineByNoteHtml}
              />
            )}

            {online.url && online.urlCtaText && (
              <VoterEdUrl
                urlString={online.url}
                locale={locale}
                textPrompt={online.urlCtaText}
              />
            )}

            {online.alternateUrl && online.alternateUrlCtaText && (
              <VoterEdUrl
                urlString={online.alternateUrl}
                locale={locale}
                textPrompt={online.alternateUrlCtaText}
              />
            )}
          </>
        )}
      {mail &&
        (mail.copyHtml ??
          mail.url ??
          mail.urlCtaText ??
          registerPostmarkBy ??
          registerPostmarkByNoteHtml) && (
          <>
            <h3>
              <FormattedMessage defaultMessage="Register by Mail" id="/Cf42A" />
            </h3>
            {mail.copyHtml && (
              <VoterEdNote note={mail.copyHtml} locale={locale} />
            )}
            {registerPostmarkBy && (
              <VoterEdDateDeadline
                dateString={registerPostmarkBy}
                textPrompt={
                  <FormattedMessage
                    defaultMessage="Register by mail by:"
                    id="IfcdrP"
                  />
                }
                locale={locale}
                note={registerPostmarkByNoteHtml}
              />
            )}

            {mail.url && mail.urlCtaText && (
              <VoterEdUrl
                urlString={mail.url}
                locale={locale}
                textPrompt={mail.urlCtaText}
              />
            )}
          </>
        )}
      {inPerson &&
        (inPerson.copyHtml ??
          inPerson.url ??
          inPerson.urlCtaText ??
          registerInPersonBy ??
          registerInPersonByNoteHtml) && (
          <>
            <h3>
              <FormattedMessage
                defaultMessage="Register in Person"
                id="9jmfu+"
              />
            </h3>
            {inPerson.copyHtml && (
              <VoterEdNote note={inPerson.copyHtml} locale={locale} />
            )}
            {registerInPersonBy && (
              <VoterEdDateDeadline
                dateString={registerInPersonBy}
                locale={locale}
                textPrompt={
                  <FormattedMessage
                    defaultMessage="Register in person by: "
                    id="27DC6y"
                  />
                }
                note={registerInPersonByNoteHtml}
              />
            )}

            {inPerson.url && inPerson.urlCtaText && (
              <VoterEdUrl
                urlString={inPerson.url}
                locale={locale}
                textPrompt={inPerson.urlCtaText}
              />
            )}
          </>
        )}
      {registerSameDayNoteHtml && (
        <>
          <h3>
            <FormattedMessage
              defaultMessage="Same Day Registration"
              id="zmXzcM"
            />
          </h3>
          {electionDay && (
            <VoterEdDateDeadline
              dateString={electionDay}
              locale={locale}
              textPrompt={
                <FormattedMessage
                  defaultMessage="Register on Election Day: "
                  id="OPAgtD"
                />
              }
              note={registerSameDayNoteHtml}
            />
          )}
        </>
      )}
    </div>
  );
};
