import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { JurisdictionLocaleSupport } from '../baseline/contentful';

import { ActiveLocale } from '../baseline/utils';

import { LocaleSelector } from './LocaleSelector';

type AdditionalLocaleSelectorsProps = {
  additionalLocales: JurisdictionLocaleSupport;
  currentLocale: ActiveLocale;
  updateSelectedLocale: (selectedLocale: ActiveLocale) => void;
};

export const AdditionalLocaleSelectors: React.FC<
  AdditionalLocaleSelectorsProps
> = ({ additionalLocales, updateSelectedLocale }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const additionalLocaleOptions = additionalLocales.map(
    (localeOption: ActiveLocale) => {
      const handleClick = () => {
        toggleExpandedOptions();
        updateSelectedLocale(localeOption);
      };
      return (
        <li role="menuitem" key={localeOption}>
          <LocaleSelector
            displayLocale={localeOption}
            onClick={() => handleClick()}
          />
        </li>
      );
    }
  );

  const toggleExpandedOptions = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <button
        className="more-locale-options"
        type="button"
        aria-haspopup="menu"
        aria-owns="locales-menu"
        aria-label="Additional localization options"
        onClick={toggleExpandedOptions}
      >
        <FormattedMessage
          id="Sxkh1O"
          defaultMessage="More"
          description="Expands language options"
        />{' '}
        <span className="more-locales-arrow">▼</span>
      </button>
      <div
        className={
          isExpanded ? 'show-additional-locales' : 'hide-additional-locales'
        }
      >
        <ul id="locales-menu" role="menu">
          {additionalLocaleOptions}
        </ul>
      </div>
    </>
  );
};
