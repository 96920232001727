/*
 */

import { Jurisdiction, Jurisdictions } from '../data/jurisdictions';
import { JurisdictionButton } from '../utils';

import {
  getCustomButtons,
  ExternalURLs,
  jurisdictionURLs,
} from './external-links';

const JursidictionLongNames = Object.values(Jurisdictions.allStringsEn);

const ShowPrecinct: Jurisdiction[] = ['NV'];

export type JurisdictionSettings = {
  abbreviation: Jurisdiction;
  longName: (typeof JursidictionLongNames)[number];
  customButtons: JurisdictionButton[];
  showPrecinct: boolean;
  urls: ExternalURLs;
};

export const getJurisdictionSettings = (
  jurisdiction: Jurisdiction
): JurisdictionSettings => ({
  abbreviation: jurisdiction,
  longName: Jurisdictions.allStringsEn[jurisdiction],
  customButtons: getCustomButtons(jurisdiction),
  showPrecinct: ShowPrecinct.includes(jurisdiction),
  urls: Jurisdictions.isState(jurisdiction)
    ? jurisdictionURLs[jurisdiction]
    : ({} as ExternalURLs),
});
