import * as React from 'react';

import { useIntl } from 'react-intl';

import {
  ActiveJurisdictions,
  Jurisdiction,
  Jurisdictions,
} from '@dnc/baseline';

type JurisdictionSelectProps = {
  residentPrefix?: boolean;
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  name: string;
  omitBlank?: boolean;
  onChange: React.FormEventHandler<HTMLSelectElement>;
  placeholder?: React.ReactNode;
  required?: boolean;
  optionTemplate?: React.ReactNode;
  value: Jurisdiction | '';
};

const JurisdictionSelect: React.FC<JurisdictionSelectProps> = (props) => {
  // Jurisdiction codes do not have the same alphabetical
  // order as their names. Order should be determined by the
  // localized jurisdiction name, not the two-letter code.
  // So, first map the labels, then sort.
  const intl = useIntl();
  const options = ActiveJurisdictions.map((jurisdiction: Jurisdiction) => {
    // e.g. "I'm a resident of the District of Columbia"
    const residentOfLocalizedJurisdiction =
      Jurisdictions.localizedWithResidentOfPrefix(jurisdiction, intl);

    // e.g. "District of Columbia"
    const localizedJurisdiction = Jurisdictions.localized(jurisdiction, intl);

    return {
      value: jurisdiction,
      nameForSort: localizedJurisdiction,
      label: props.residentPrefix
        ? residentOfLocalizedJurisdiction
        : localizedJurisdiction,
    };
  }).sort((a, b) => (a.nameForSort > b.nameForSort ? 1 : -1));

  return (
    <select
      autoComplete={props.autoComplete}
      className={props.className}
      disabled={props.disabled}
      name={props.name}
      id={props.id || props.name}
      onChange={props.onChange}
      required={props.required}
      value={props.value}
    >
      {props.omitBlank || !props.placeholder ? null : (
        <option value="">{props.placeholder}</option>
      )}
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export { JurisdictionSelect };
