import { Collapse } from '@mui/material';
import * as React from 'react';

import CloseIcon from '../../../public/close.svg';
import OpenIcon from '../../../public/open.svg';

export type WidgetInfoSectionProps = {
  sectionName: string;
  sectionHeader: React.ReactNode;
  icon: React.ReactNode;
  children?: React.ReactNode;
};
export const WidgetInfoSection: React.FC<WidgetInfoSectionProps> = ({
  sectionName,
  sectionHeader,
  icon,
  children,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  return (
    <section className="widget-voter-education__info-section" id={sectionName}>
      <div
        className="widget-voter-education__info-section__header"
        role="button"
        tabIndex={0}
        onKeyDown={(event) => {
          // check for Enter or Space key
          if ([13, 32].includes(event.keyCode)) {
            setExpanded(!expanded);
          }
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <div className="widget-voter-education__info-section__icon-container">
          {icon}
        </div>
        <h2>{sectionHeader}</h2>
        <div className="widget-voter-education__info-section__expand">
          {expanded ? <CloseIcon /> : <OpenIcon />}
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </section>
  );
};
