import * as React from 'react';
import { FormattedDate } from 'react-intl';

import {
  ActiveLocale,
  ActiveLocalizedUrl,
  VepApiActiveLocalizedNullableString,
  getLocalizedContent,
} from '@dnc/baseline';

import ExternalLinkIcon from '../../../public/external-link.svg';

export const VoterEdNote: React.FC<{
  locale: ActiveLocale;
  note: VepApiActiveLocalizedNullableString;
}> = ({ locale, note }) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: getLocalizedContent(locale, note),
      }}
    />
  );
};

export const VoterEdDateDeadline: React.FC<{
  dateString: string;
  textPrompt?: React.ReactNode;
  note?: VepApiActiveLocalizedNullableString | null;
  locale: ActiveLocale;
}> = ({ dateString, textPrompt, note, locale }) => {
  return (
    <p className="widget-voter-education__deadline">
      <div className="widget-voter-education__deadline__label">
        {textPrompt}
      </div>

      <div className="widget-voter-education__deadline__date-container">
        <div className="widget-voter-education__deadline__date">
          <FormattedDate
            year="numeric"
            month="long"
            day="numeric"
            value={dateString}
            // Need UTC here otherwise FormattedDate improperly converts
            // dateString to be one day behind the actual date
            timeZone="UTC"
          />
        </div>

        {note && (
          <div
            className="widget-voter-education__deadline__note"
            dangerouslySetInnerHTML={{
              __html: getLocalizedContent(locale, note),
            }}
          />
        )}
      </div>
    </p>
  );
};

export const VoterEdUrl: React.FC<{
  urlString: ActiveLocalizedUrl | VepApiActiveLocalizedNullableString;
  locale: ActiveLocale;
  textPrompt?: ActiveLocalizedUrl | VepApiActiveLocalizedNullableString;
  formattedMessage?: React.ReactNode;
}> = ({ urlString, locale, textPrompt, formattedMessage }) => {
  return (
    <p>
      <a
        className="widget-voter-education__link"
        href={getLocalizedContent(locale, urlString)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ExternalLinkIcon />

        <span>
          {textPrompt && getLocalizedContent(locale, textPrompt)}
          {formattedMessage && formattedMessage}
        </span>
      </a>
    </p>
  );
};
