import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { VepApiActiveLocalizedNullableString } from '@dnc/baseline';

import {
  VepApiImportantDatesAndDeadlines,
  VepApiRegistrationOptions,
  VepApiElectionInfo,
  VepApiPollingLocationLookup,
  VepApiVoteByMailInfo,
} from '../../../baseline/ve-papi/types';

import HowToCompleteBallotIcon from './../../../public/how-to-complete-ballot.svg';
import RegistrationEligibilityIcon from './../../../public/registration-eligibility.svg';
import VotingIdRequirementsIcon from './../../../public/voter-id-requirements.svg';
import MailInBallotIcon from './../../../public/voting-info/mail-in-ballot-icon.svg';

import { WidgetIdRequirementsSection } from './widget-id-requirements-section';
import { WidgetInPersonSection } from './widget-in-person-section';
import { WidgetInfoSection } from './widget-info-section';
import { WidgetMailAbsenteeSection } from './widget-mail-absentee-section';
import { WidgetRegistrationSection } from './widget-registration-section';

export type WidgetDisplayExpandableProps = {
  importantDatesAndDeadlines: VepApiImportantDatesAndDeadlines | null;
  voterRegistrationInfo: VepApiRegistrationOptions;
  checkRegistrationUrl: VepApiActiveLocalizedNullableString;
  electionInfo: VepApiElectionInfo | null;
  pollingLocationLookup: VepApiPollingLocationLookup;
  voteByMailInfo: VepApiVoteByMailInfo | null;
  idRequirements: VepApiActiveLocalizedNullableString | null;
};
export const WidgetDisplayExpandable: React.FC<
  WidgetDisplayExpandableProps
> = ({
  importantDatesAndDeadlines,
  voteByMailInfo,
  voterRegistrationInfo,
  electionInfo,
  checkRegistrationUrl,
  idRequirements,
  pollingLocationLookup,
}) => {
  /**
   * This function checks if an object is "empty".
   * Checking if an object exists or not does not return true/false in the format
   * below (the object always exists), so this step is necessary to check if an
   * object does not have information to display.
   */
  function shouldDisplayContent(obj: object) {
    for (const [_, value] of Object.entries(obj)) {
      if (value && typeof value === 'object') {
        if (shouldDisplayContent(value)) {
          return true;
        }
      } else {
        if (typeof value === 'string') {
          return true;
        }
      }
    }
    return false;
  }

  return (
    <>
      {(importantDatesAndDeadlines ||
        shouldDisplayContent(voterRegistrationInfo) ||
        shouldDisplayContent(checkRegistrationUrl)) && (
        <WidgetInfoSection
          sectionName="voter-registration"
          sectionHeader={
            <FormattedMessage
              defaultMessage="Register to Vote"
              id="vepWidget.register"
            />
          }
          icon={<RegistrationEligibilityIcon />}
        >
          <WidgetRegistrationSection
            importantDatesAndDeadlines={importantDatesAndDeadlines}
            voterRegistrationInfo={voterRegistrationInfo}
            checkRegistrationUrl={checkRegistrationUrl}
          />
        </WidgetInfoSection>
      )}
      {(importantDatesAndDeadlines ||
        electionInfo ||
        shouldDisplayContent(pollingLocationLookup)) && (
        <WidgetInfoSection
          sectionName="in-person"
          sectionHeader={
            <FormattedMessage
              defaultMessage="Vote in Person"
              id="vepWidget.inPerson"
            />
          }
          icon={<HowToCompleteBallotIcon />}
        >
          <WidgetInPersonSection
            electionInfo={electionInfo}
            pollingLocationLookup={pollingLocationLookup}
            importantDatesAndDeadlines={importantDatesAndDeadlines}
          />
        </WidgetInfoSection>
      )}
      {(importantDatesAndDeadlines || voteByMailInfo) && (
        <WidgetInfoSection
          sectionName="by-mail"
          sectionHeader={
            <FormattedMessage
              defaultMessage="Vote by Mail"
              id="vepWidget.mail"
            />
          }
          icon={<MailInBallotIcon />}
        >
          <WidgetMailAbsenteeSection
            importantDatesAndDeadlines={importantDatesAndDeadlines}
            voteByMailInfo={voteByMailInfo}
          />
        </WidgetInfoSection>
      )}
      {idRequirements && (
        <WidgetInfoSection
          sectionName="voter-id"
          sectionHeader={
            <FormattedMessage
              defaultMessage="Voter ID Requirements"
              id="vepWidget.requirements"
            />
          }
          icon={<VotingIdRequirementsIcon />}
        >
          <WidgetIdRequirementsSection idRequirements={idRequirements} />
        </WidgetInfoSection>
      )}
    </>
  );
};
