import { QueryClient, queryOptions } from '@tanstack/react-query';
import moment from 'moment';

import {
  ElectionInfo,
  ElectionsService,
  Jurisdiction,
  LookupErrorCode,
  NoElectionsError,
  Option,
  SiteType,
  VISService,
  VISVotingLocations,
  VISVotingLocationsSuccess,
} from '@dnc/baseline';

import {
  filterLocationsList,
  getEligibleSiteTypes,
  isElectionDay,
  SiteTypes,
} from '../components/locate/utils/location';

import type { ContentfulParams } from './url-helper';
import { validatedJurisdiction } from './utils';

export class VISLookupError extends Error {
  errorCode: LookupErrorCode;

  jurisdiction: Option<Jurisdiction>;

  constructor(errorCode: LookupErrorCode, jurisdiction: Option<Jurisdiction>) {
    super(`Lookup failed with error code: ${errorCode}`);
    this.name = 'VISLookupError';
    this.errorCode = errorCode;
    this.jurisdiction = jurisdiction;

    // Set the prototype explicitly.
    // See https://github.com/facebook/jest/issues/8279#issuecomment-539775425
    Object.setPrototypeOf(this, VISLookupError.prototype);
  }
}

// For each site type, filter out locations that should
// not be displayed.
// e.g., exclude early vote locations on election day.
export function filterPayload(
  dncResults: VISVotingLocationsSuccess,
  electionInfo: ElectionInfo,
  today: moment.Moment
): VISVotingLocationsSuccess {
  const augmentedResults = { ...dncResults };

  SiteTypes.forEach((siteType: SiteType) => {
    const locations = dncResults[siteType];
    const filteredLocations = filterLocationsList(
      locations,
      electionInfo,
      today
    );
    augmentedResults[siteType] = filteredLocations;
  });

  return augmentedResults;
}

// Check if filtered results contain eligible locations
export function hasEligibleResults(
  dncResults: VISVotingLocationsSuccess,
  electionInfo: ElectionInfo,
  today: moment.Moment,
  jurisdiction: Jurisdiction
): boolean {
  const isEday = isElectionDay(electionInfo.electionDay, today);
  const eligibleSiteTypes = getEligibleSiteTypes(
    dncResults,
    isEday,
    jurisdiction
  );
  return eligibleSiteTypes.length > 0;
}

async function handleLookupResults(
  electionsService: ElectionsService,
  results: VISVotingLocations,
  isPreview: boolean,
  isNewConfig: boolean,
  today: moment.Moment
): Promise<{
  pollingData: VISVotingLocationsSuccess;
  electionInfo: ElectionInfo;
}> {
  // Check status from VIS
  const succeeded = results.status === 'SUCCESS';

  // Check if VIS found a valid normalized jurisdiction
  const validJurisdiction = validatedJurisdiction(
    results.voterAddress?.normalizedAddress?.stateCode
  );

  // Check if an active election exists for said jurisdiction
  if (succeeded && validJurisdiction) {
    const electionInfo = await electionsService.getNextElection(
      validJurisdiction,
      isPreview,
      isNewConfig,
      today
    );

    // Only return results if an election is active
    if (electionInfo && results) {
      const filteredResults = filterPayload(results, electionInfo, today);
      // Raise an error if valid location types are empty
      const hasValidLocations = hasEligibleResults(
        filteredResults,
        electionInfo,
        today,
        validJurisdiction
      );

      if (!hasValidLocations) {
        throw new VISLookupError(
          'NO_VOTING_LOCATIONS_AVAILABLE',
          validJurisdiction
        );
      }
      return {
        pollingData: filteredResults,
        electionInfo,
      };
    }
    throw new NoElectionsError(validJurisdiction);
  }

  const errorCode =
    (results.status === 'ERROR' && results.errorCode) || 'DEFAULT';

  throw new VISLookupError(errorCode, validJurisdiction);
}

/**
 * Looks up polling places.
 *
 * Backed by a react-query cache to prevent duplicate fetches when we change the
 * URL to reflect changes in jurisdiction.
 */
export class PollingPlaceService {
  private readonly electionsService: ElectionsService;
  private readonly visService: VISService;
  private readonly queryClient: QueryClient;

  constructor({
    electionsService,
    visService,
    queryClient,
  }: {
    electionsService: ElectionsService;
    visService: VISService;
    queryClient: QueryClient;
  }) {
    this.electionsService = electionsService;
    this.visService = visService;
    this.queryClient = queryClient;
  }

  /**
   * Fetches the voting location info for a given address from VIS and filters it
   * with information about the upcoming election.
   *
   * May throw {@link NoElectionsError} or {@link VISLookupError}.
   *
   * Caches the result with react-query.
   */
  public async fetchPollingDataForAddress({
    contentfulParams,
    address,
    state,
    clientId,
    organization,
    resultsPerType,
  }: {
    contentfulParams: ContentfulParams;
    address: string;
    state: Option<Jurisdiction>;
    clientId: string;
    organization?: Option<string>;
    resultsPerType: number;
  }): Promise<{
    pollingData: VISVotingLocationsSuccess;
    electionInfo: ElectionInfo;
  }> {
    const previewBool: boolean =
      contentfulParams.preview !== undefined ? contentfulParams.preview : false;
    const configBool: boolean =
      contentfulParams.test_config !== undefined
        ? contentfulParams.test_config
        : false;

    const dncResults = await this.queryClient.fetchQuery({
      staleTime: 5 * 60 * 1000,
      queryKey: [
        'lookupValidatedVotingLocations',
        address,
        clientId,
        resultsPerType,
      ],
      queryFn: () =>
        this.visService.lookUpVotingLocations({
          address,
          clientId,
          organization,
          state,
          resultsPerType,
        }),
    });

    return handleLookupResults(
      this.electionsService,
      dncResults,
      previewBool,
      configBool,
      moment()
    );
  }

  /**
   * react-query {@link QueryOptions} for loading voting location summaries
   *
   * Fetches a national display of voting location status info from VIS,
   * to provide a handy visualization for third-party vendors regarding
   * which states have which location type(s) active
   *
   * Note that these options return `null` instead of `undefined` to match with
   * react-query’s semantics.
   */
  public nationalVotingLocationsSummaryQueryOptions() {
    const clientId = 'site';
    return queryOptions({
      staleTime: 5 * 60 * 1000,
      queryKey: ['getNationalVotingLocationsSummary', clientId],
      queryFn: async () =>
        (await this.visService.getNationalVotingLocationsSummary(clientId)) ??
        null,
    });
  }
}
