import moment from 'moment';

import { ElectionInfo } from '../data';
import { Jurisdiction, Jurisdictions, State } from '../data/jurisdictions';

import { VoterEducationService } from './voter-education-service';

export const ElectionErrorCodeIDs = [
  'NO_ACTIVE_ELECTION',
  'UNKNOWN_JURISDICTION',
];

export type ElectionErrorCode = (typeof ElectionErrorCodeIDs)[number];

export class NoElectionsError extends Error {
  jurisdiction: Jurisdiction | string;

  errorCode: ElectionErrorCode;

  constructor(
    jurisdiction: Jurisdiction | string,
    errorCode = 'NO_ACTIVE_ELECTION' as ElectionErrorCode
  ) {
    super(`No active election for ${jurisdiction}`);
    this.jurisdiction = jurisdiction;
    this.errorCode = errorCode;
    this.name = 'NoElectionsError';

    // Set the prototype explicitly.
    // See https://github.com/facebook/jest/issues/8279#issuecomment-539775425
    Object.setPrototypeOf(this, NoElectionsError.prototype);
  }
}

// copied from Contentful : 10/30/24
export const general2024EvConfigBackup: {
  [key in State]: ElectionInfo['earlyVoting'];
} = {
  AK: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-05'),
  },
  AL: {
    allowed: false,
  },
  AR: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-04'),
  },
  AZ: {
    allowed: true,
    startDate: moment('2024-10-09'),
    endDate: moment('2024-11-04'),
  },
  CA: {
    allowed: true,
    startDate: moment('2024-10-07'),
    endDate: moment('2024-11-04'),
  },
  CO: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-04'),
  },
  CT: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-03'),
  },
  DC: {
    allowed: true,
    startDate: moment('2024-10-28'),
    endDate: moment('2024-11-03'),
  },
  DE: {
    allowed: true,
    startDate: moment('2024-10-25'),
    endDate: moment('2024-11-03'),
  },
  FL: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-03'),
  },
  GA: {
    allowed: true,
    startDate: moment('2024-10-15'),
    endDate: moment('2024-11-01'),
  },
  HI: {
    allowed: true,
    startDate: moment('2024-10-18'),
    endDate: moment('2024-11-04'),
  },
  IA: {
    allowed: true,
    startDate: moment('2024-10-16'),
    endDate: moment('2024-11-04'),
  },
  ID: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-01'),
  },
  IL: {
    allowed: true,
    startDate: moment('2024-09-26'),
    endDate: moment('2024-11-04'),
  },
  IN: {
    allowed: true,
    startDate: moment('2024-10-08'),
    endDate: moment('2024-11-04'),
  },
  KS: {
    allowed: true,
    startDate: moment('2024-10-16'),
    endDate: moment('2024-11-04'),
  },
  KY: {
    allowed: true,
    startDate: moment('2024-10-31'),
    endDate: moment('2024-11-02'),
  },
  LA: {
    allowed: true,
    startDate: moment('2024-10-18'),
    endDate: moment('2024-10-29'),
  },
  MA: {
    allowed: true,
    startDate: moment('2024-10-19'),
    endDate: moment('2024-11-01'),
  },
  MD: {
    allowed: true,
    startDate: moment('2024-10-24'),
    endDate: moment('2024-10-31'),
  },
  ME: {
    allowed: true,
    startDate: moment('2024-10-07'),
    endDate: moment('2024-10-31'),
  },
  MI: {
    allowed: true,
    startDate: moment('2024-10-26'),
    endDate: moment('2024-11-03'),
  },
  MN: {
    allowed: true,
    startDate: moment('2024-09-20'),
    endDate: moment('2024-11-04'),
  },
  MO: {
    allowed: true,
    startDate: moment('2024-10-22'),
    endDate: moment('2024-11-04'),
  },
  MS: {
    allowed: false,
  },
  MT: {
    allowed: true,
    startDate: moment('2024-10-10'),
    endDate: undefined,
  },
  NC: {
    allowed: true,
    startDate: moment('2024-10-17'),
    endDate: moment('2024-11-02'),
  },
  ND: {
    allowed: true,
    startDate: moment('2024-10-26'),
    endDate: moment('2024-11-04'),
  },
  NE: {
    allowed: true,
    startDate: moment('2024-10-07'),
    endDate: moment('2024-11-04'),
  },
  NH: {
    allowed: false,
  },
  NJ: {
    allowed: true,
    startDate: moment('2024-10-26'),
    endDate: moment('2024-11-03'),
  },
  NM: {
    allowed: true,
    startDate: moment('2024-10-08'),
    endDate: moment('2024-11-02'),
  },
  NV: {
    allowed: true,
    startDate: moment('2024-10-19'),
    endDate: moment('2024-11-01'),
  },
  NY: {
    allowed: true,
    startDate: moment('2024-10-26'),
    endDate: moment('2024-11-03'),
  },
  OH: {
    allowed: true,
    startDate: moment('2024-10-08'),
    endDate: moment('2024-11-03'),
  },
  OK: {
    allowed: true,
    startDate: moment('2024-10-30'),
    endDate: moment('2024-11-02'),
  },
  OR: {
    allowed: true,
    startDate: moment('2024-10-16'),
    endDate: moment('2024-11-05'),
  },
  PA: {
    allowed: true,
    startDate: moment('2024-09-17'),
    endDate: moment('2024-10-29'),
  },
  RI: {
    allowed: true,
    startDate: moment('2024-10-16'),
    endDate: moment('2024-11-04'),
  },
  SC: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-02'),
  },
  SD: {
    allowed: true,
    startDate: moment('2024-09-20'),
    endDate: moment('2024-11-04'),
  },
  TN: {
    allowed: true,
    startDate: moment('2024-10-16'),
    endDate: moment('2024-10-31'),
  },
  TX: {
    allowed: true,
    startDate: moment('2024-10-21'),
    endDate: moment('2024-11-01'),
  },
  UT: {
    allowed: true,
    startDate: moment('2024-10-07'),
    endDate: moment('2024-11-04'),
  },
  VA: {
    allowed: true,
    startDate: moment('2024-09-20'),
    endDate: moment('2024-11-03'),
  },
  VT: {
    allowed: true,
    startDate: moment('2024-09-21'),
    endDate: moment('2024-11-04'),
  },
  WA: {
    allowed: true,
    startDate: moment('2024-10-18'),
    endDate: moment('2024-11-04'),
  },
  WI: {
    allowed: true,
    startDate: moment('2024-10-22'),
    endDate: moment('2024-11-03'),
  },
  WV: {
    allowed: true,
    startDate: moment('2024-10-23'),
    endDate: moment('2024-11-02'),
  },
  WY: {
    allowed: true,
    startDate: moment('2024-10-08'),
    endDate: moment('2024-11-04'),
  },
};
export class ElectionsService {
  // We use VoterEducationService because it has a cache.
  private readonly voterEducationService: VoterEducationService;

  constructor({
    voterEducationService,
  }: {
    voterEducationService: VoterEducationService;
  }) {
    this.voterEducationService = voterEducationService;
  }

  public async getNextElection(
    jurisdiction: Jurisdiction,
    isPreview: boolean,
    isNewConfig: boolean,
    today: moment.Moment
  ) {
    try {
      const jurisdictionInfo =
        this.voterEducationService.fetchJurisdictionConfig(
          jurisdiction,
          isPreview,
          isNewConfig,
          today
        );
      return (await jurisdictionInfo)?.electionInfo;
    } catch (e) {
      if (today.isSameOrBefore('2024-11-05')) {
        const backupActiveElection: ElectionInfo = {
          internalName: 'November 5, 2024 General Election',
          electionType: 'general',
          earlyVoting: Jurisdictions.isState(jurisdiction)
            ? general2024EvConfigBackup[jurisdiction]
            : {
                allowed: false,
              },
          electionDay: moment('2024-11-05'),
        };
        return backupActiveElection;
      } else {
        return undefined;
      }
    }
  }
}

export type ElectionsProvider = {
  elections: ElectionsService;
};
