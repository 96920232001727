import * as React from 'react';

import { VepApiActiveLocalizedNullableString } from '@dnc/baseline';
import { LocaleContext } from '@dnc/shared-components';

import { VoterEdNote } from './widget-component-utils';

type WidgetIdRequirementsSectionProps = {
  idRequirements: VepApiActiveLocalizedNullableString;
};

export const WidgetIdRequirementsSection: React.FC<
  WidgetIdRequirementsSectionProps
> = ({ idRequirements }) => {
  const locale = React.useContext(LocaleContext);
  return (
    <div className="widget-voter-education__components">
      <VoterEdNote note={idRequirements} locale={locale} />
    </div>
  );
};
