import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { LocaleContext } from '@dnc/shared-components';

import {
  VepApiImportantDatesAndDeadlines,
  VepApiVoteByMailInfo,
} from '../../../baseline/ve-papi/types';

import { VoterEdDateDeadline, VoterEdUrl } from './widget-component-utils';

type WidgetMailAbsenteeSectionProps = {
  importantDatesAndDeadlines: VepApiImportantDatesAndDeadlines | null;
  voteByMailInfo: VepApiVoteByMailInfo | null;
};

export const WidgetMailAbsenteeSection: React.FC<
  WidgetMailAbsenteeSectionProps
> = ({ importantDatesAndDeadlines, voteByMailInfo }) => {
  const {
    ballotDropoffBy,
    ballotDropoffByNoteHtml,
    ballotReceiveBy,
    ballotReceiveByNoteHtml,
    ballotRequestBy,
    ballotRequestByNoteHtml,
  } = importantDatesAndDeadlines ?? {};
  const { sosBallotRequestUrl } = voteByMailInfo ?? {};
  const locale = React.useContext(LocaleContext);

  return (
    <div className="widget-voter-education__components">
      {(sosBallotRequestUrl || ballotRequestBy || ballotRequestByNoteHtml) && (
        <>
          <h3>
            <FormattedMessage
              defaultMessage="Requesting Your By-Mail Ballot"
              id="G+CLh4"
            />
          </h3>
          {sosBallotRequestUrl && (
            <VoterEdUrl
              urlString={sosBallotRequestUrl}
              locale={locale}
              formattedMessage={
                <FormattedMessage
                  defaultMessage="Request your vote-by-mail ballot"
                  id="fYFYjF"
                />
              }
            />
          )}
          {ballotRequestBy && (
            <VoterEdDateDeadline
              dateString={ballotRequestBy}
              locale={locale}
              textPrompt={
                <FormattedMessage
                  defaultMessage="Your ballot must be requested by: "
                  id="m9UJZt"
                />
              }
              note={ballotRequestByNoteHtml}
            />
          )}
        </>
      )}
      {(ballotDropoffBy ||
        ballotDropoffByNoteHtml ||
        ballotReceiveBy ||
        ballotReceiveByNoteHtml) && (
        <>
          <h3>
            <FormattedMessage
              defaultMessage="Returning Your Ballot"
              id="C5v7/a"
            />
          </h3>
          {(ballotDropoffBy || ballotDropoffByNoteHtml) && (
            <>
              {ballotDropoffBy && (
                <VoterEdDateDeadline
                  dateString={ballotDropoffBy}
                  locale={locale}
                  textPrompt={
                    <FormattedMessage
                      defaultMessage="Return your ballot by: "
                      id="TMrxC3"
                    />
                  }
                  note={ballotDropoffByNoteHtml}
                />
              )}
            </>
          )}
          {(ballotReceiveBy || ballotReceiveByNoteHtml) && (
            <>
              {ballotReceiveBy && (
                <VoterEdDateDeadline
                  dateString={ballotReceiveBy}
                  locale={locale}
                  textPrompt={
                    <FormattedMessage
                      defaultMessage="Your ballot must be received by your election official by: "
                      id="DKswpg"
                    />
                  }
                  note={ballotReceiveByNoteHtml}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
