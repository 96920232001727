import * as React from 'react';
import { IntlProvider } from 'react-intl';

import {
  ActiveLocale,
  ActiveLanguageToLocale,
  State,
  Configuration,
  Jurisdictions,
} from '@dnc/baseline';
import { LocaleContext } from '@dnc/shared-components';

import { VepApiStateData } from '../../../baseline/ve-papi/types';
import {
  DEFAULT_LOCALE,
  DEFAULT_RICH_TEXT_ELEMENTS,
  locales,
} from '../../../i18n/i18n-config';

import VepWidgetView from './VepWidgetView';

export type VepWidgetConfiguration = {
  locale?: ActiveLocale;
  state?: State;
  organization?: string;
  apiKey?: string;
  showHeader?: boolean;
  hideJurisdictionSelector?: boolean;
};

type VepWidgetRootProps = {
  config?: VepWidgetConfiguration;
};

const configuration = process.env.CONFIGURATION || Configuration.development;

const visUrlBase =
  configuration === Configuration.production
    ? 'https://vis.iwillvote.com'
    : 'https://vis.stage.dnc.org';

// We need a Root component for React Hot Loading.
export const VepWidgetRoot: React.FC<VepWidgetRootProps> = ({ config }) => {
  const { locale, state, organization, apiKey, showHeader } = config || {};
  const defaultLocale: ActiveLocale = ActiveLanguageToLocale['English'];
  const currentLocale = locale || defaultLocale;
  const [displayState, setDisplayState] = React.useState<State | undefined>(
    state
  );
  // Update the state/jurisdiction when a new state is selected
  // from the dropdown (this is only necessary for the VepBackup component)
  React.useEffect(() => {
    setDisplayState(state);
  }, [state]);

  const [vepResult, setVepResult] = React.useState<{
    stateCode: State | null;
    result: VepApiStateData | null;
  }>({ stateCode: null, result: null });

  const requestApiKey = apiKey ?? (process.env.VEP_API_KEY as string);

  React.useEffect(() => {
    // Skipping the test case for this because the test was failing due to "No fallback response"
    if (displayState === undefined && process.env.NODE_ENV !== 'test') {
      const visGeoIpPath = '/geo-ip';
      const geoIpUrl = visUrlBase + visGeoIpPath;
      fetch(geoIpUrl, {
        method: 'GET',
        headers: {
          'X-API-Key': requestApiKey,
        },
      })
        .then(async (response) => {
          const responseJson = await response.json();
          const geoIpState = responseJson.region_code;
          if (Jurisdictions.isState(geoIpState)) {
            setDisplayState((prev) => prev ?? geoIpState);
          }
        })
        .catch((error) => {
          console.error(`Error loading geo-ip: ${error}`);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (displayState) {
      const vepPath = `/vep/state/${displayState}?client_id=voter_education_widget${organization ? `&organization=${organization}` : ''}`;
      const vepApiUrl = visUrlBase + vepPath;
      setVepResult({ stateCode: displayState, result: null });
      fetch(vepApiUrl, {
        method: 'GET',
        headers: {
          'X-API-Key': requestApiKey,
        },
      })
        .then(async (response) => {
          const responseJson = await response.json();
          setVepResult((prev) => {
            if (prev.stateCode == displayState) {
              return { stateCode: displayState, result: responseJson };
            } else {
              return prev;
            }
          });
        })
        .catch((error) => {
          console.error(`Error loading vep info: ${error}`);
          throw error;
        });
    } else {
      // don't show stale data if the displayState is undefined
      setVepResult({ stateCode: null, result: null });
    }
  }, [displayState, requestApiKey, organization]);

  // Suppress console warnings about non-precompiled messages in tests
  const onWarn = process.env.NODE_ENV === 'test' ? () => {} : undefined;

  return (
    <LocaleContext.Provider value={currentLocale}>
      <IntlProvider
        locale={currentLocale}
        defaultLocale={DEFAULT_LOCALE}
        messages={locales[currentLocale].messages}
        defaultRichTextElements={DEFAULT_RICH_TEXT_ELEMENTS}
        onWarn={onWarn}
      >
        <VepWidgetView
          selectedState={displayState}
          setDisplayState={setDisplayState}
          selectedStateData={vepResult.result}
          showHeader={showHeader || false}
          hideWidgetSelector={config?.hideJurisdictionSelector}
        />
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default VepWidgetRoot;
