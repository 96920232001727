import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { LocaleContext } from '@dnc/shared-components';

import {
  VepApiElectionInfo,
  VepApiImportantDatesAndDeadlines,
  VepApiPollingLocationLookup,
} from '../../../baseline/ve-papi/types';

import { VoterEdDateDeadline, VoterEdUrl } from './widget-component-utils';

type WidgetInPersonSectionProps = {
  electionInfo: VepApiElectionInfo | null;
  pollingLocationLookup: VepApiPollingLocationLookup;
  importantDatesAndDeadlines: VepApiImportantDatesAndDeadlines | null;
};

export const WidgetInPersonSection: React.FC<WidgetInPersonSectionProps> = ({
  electionInfo,
  pollingLocationLookup,
  importantDatesAndDeadlines,
}) => {
  const { iwvLookupUrl, sosLookupUrl } = pollingLocationLookup;
  const { startDate, endDate } = electionInfo?.earlyVoting ?? {};
  const {
    electionDay,
    earlyVotingStartBy,
    earlyVotingStartByNoteHtml,
    electionDayStatewideHoursText,
    inPersonAbsenteeStartBy,
    inPersonAbsenteeStartByNoteHtml,
  } = importantDatesAndDeadlines ?? {};

  const locale = React.useContext(LocaleContext);

  // Early vote start date is either
  // electionInfo > earlyVote > startDate
  // or importantDatesAndDeadlines > earlyVotingStartBy
  const evStartDate = startDate ?? earlyVotingStartBy;

  const determineLookupUrl = () => {
    if (iwvLookupUrl && !sosLookupUrl) {
      return iwvLookupUrl;
    }
    if (!iwvLookupUrl && sosLookupUrl) {
      return sosLookupUrl;
    }
  };
  // TODO: distinguish EV/Eday Lookups?
  const evLookupUrl = determineLookupUrl();
  const edayLookupUrl = determineLookupUrl();

  return (
    <div className="widget-voter-education__components">
      {(evStartDate || endDate) && (
        <>
          <h3>
            <FormattedMessage defaultMessage="Vote Early" id="cc/NEI" />
          </h3>
          {evLookupUrl && (
            <VoterEdUrl
              urlString={evLookupUrl}
              locale={locale}
              formattedMessage={
                <FormattedMessage
                  defaultMessage="Find your early voting site"
                  id="6q6K7R"
                />
              }
            />
          )}
          {evStartDate && (
            <VoterEdDateDeadline
              dateString={evStartDate}
              locale={locale}
              textPrompt={
                <FormattedMessage
                  defaultMessage="Early voting starts on:"
                  id="EtZxPR"
                />
              }
              note={earlyVotingStartByNoteHtml}
            />
          )}

          {inPersonAbsenteeStartBy && (
            <VoterEdDateDeadline
              dateString={inPersonAbsenteeStartBy}
              locale={locale}
              textPrompt={
                <FormattedMessage
                  defaultMessage="In person absentee voting starts on:"
                  id="Yf6XuF"
                />
              }
              note={inPersonAbsenteeStartByNoteHtml}
            />
          )}

          {endDate && (
            <VoterEdDateDeadline
              dateString={endDate}
              locale={locale}
              textPrompt={
                <FormattedMessage
                  defaultMessage="Early voting ends on:"
                  id="OOjh9G"
                />
              }
            />
          )}
        </>
      )}

      {(electionDay || electionDayStatewideHoursText) && (
        <>
          <h3>
            <FormattedMessage
              defaultMessage="Vote in Person on Election Day"
              id="gVbX81"
            />
          </h3>
          {edayLookupUrl && (
            <VoterEdUrl
              urlString={edayLookupUrl}
              locale={locale}
              formattedMessage={
                <FormattedMessage
                  defaultMessage="Find your polling place"
                  id="i8g8pf"
                />
              }
            />
          )}
          {electionDay && (
            <VoterEdDateDeadline
              dateString={electionDay}
              locale={locale}
              textPrompt={
                <FormattedMessage
                  defaultMessage="Election day is:"
                  id="Mywc+F"
                />
              }
              note={electionDayStatewideHoursText}
            />
          )}
        </>
      )}
    </div>
  );
};
