import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { Jurisdiction, Option, State } from '@dnc/baseline';

import { JurisdictionSelect } from '../../action-network-capture/jurisdiction-select';

type WidgetJurisdictionHeaderProps = {
  selectedState: Option<Jurisdiction>;
  setDisplayState: (stateCode: State) => void;
};

export const WidgetJurisdictionHeader: React.FC<
  WidgetJurisdictionHeaderProps
> = ({ selectedState, setDisplayState }) => {
  return (
    <JurisdictionSelect
      name="jurisdiction"
      className="widget-voter-education__select-jurisdiction"
      value={selectedState || ''}
      onChange={(e) => setDisplayState(e.currentTarget.value as State)}
      placeholder={
        <FormattedMessage
          defaultMessage="Select your state…"
          id="vepWidget.jurisdiction"
        />
      }
    />
  );
};
