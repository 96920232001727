import * as React from 'react';

import { Helmet } from 'react-helmet';

import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Jurisdiction, Jurisdictions, State } from '@dnc/baseline';

import { LocaleContext } from '@dnc/shared-components';

import { UrlHelper } from '../../services/url-helper';
import { ActionButton } from '../ActionButton';
import { JurisdictionSelect } from '../action-network-capture/jurisdiction-select';
import VepWidgetRoot from '../voting-info/vep-widget/VepWidgetRoot';

type VepBackupProps = {
  jurisdiction: Jurisdiction;
  updateJurisdiction: (jurisdiction: Jurisdiction) => void;
};

/** This component renders a modified version of the VEP Widget in the event that jurisdictionConfig
 * is undefined (ie Contentful is down or having issues). The widget gets its information from VEP API.
 * The header is removed from the widget and a separate header, select state dropdown, and locate button
 * that goes to /locate were added to look similar to the IWV home page while still providing similar functionality.
 */
export const VepBackup: React.FC<VepBackupProps> = ({
  jurisdiction,
  updateJurisdiction,
}) => {
  const location = useLocation();
  const urlHelper = UrlHelper.fromLocation(location);
  const locateUrl = urlHelper.locateURL(jurisdiction);

  const locale = React.useContext(LocaleContext);

  const isState = Jurisdictions.isState(jurisdiction);
  const stateCode = isState ? jurisdiction : undefined;

  // this was copied from ActionOptions and modified
  const renderLocationLookupButton = () => {
    if (locateUrl) {
      return (
        <ActionButton
          key="locate"
          buttonClass="button--cta"
          checkmark
          url={locateUrl}
          enabled
          type="locate"
        >
          <FormattedMessage
            defaultMessage="Find voting or drop-off location"
            id="options.lookup.notCaucus"
            tagName="span"
          />
        </ActionButton>
      );
    }
  };

  return (
    <div>
      <Helmet>
        <link
          href="https://iwillvote.com/voting-info/vep-widget.css"
          rel="stylesheet"
        />
      </Helmet>
      {/* The h1 and FormattedMessage were copied from ActionOptions */}
      <div>
        <h1>
          <FormattedMessage
            defaultMessage="Let's Vote"
            id="options.header"
            tagName="span"
          />
        </h1>
        <p>
          <FormattedMessage
            defaultMessage="Select your state, make sure you're registered to vote, then choose how you're going to vote this year."
            id="options.subheader"
            tagName="span"
          />
        </p>
      </div>
      <div id="i-will-vote-voter-education-widget">
        <JurisdictionSelect
          name="jurisdiction"
          className="widget-voter-education__select-jurisdiction"
          value={jurisdiction || ''}
          onChange={(e) => {
            updateJurisdiction(e.currentTarget.value as State);
          }}
          residentPrefix={true}
          placeholder={
            <FormattedMessage
              defaultMessage="Select your state…"
              id="vepWidget.jurisdiction"
            />
          }
        />
        <div className="my-2 -mb-4">{renderLocationLookupButton()}</div>
        <VepWidgetRoot
          config={{
            state: stateCode,
            locale: locale,
            hideJurisdictionSelector: true,
          }}
        />
      </div>
    </div>
  );
};
