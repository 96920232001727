import * as React from 'react';

import { Jurisdiction, Option, State } from '@dnc/baseline';

import { LocaleContext } from '@dnc/shared-components';

import { VepApiStateData } from '../../../baseline/ve-papi/types';

import { WidgetDisplayExpandable } from './widget-display-expandable';
import { VepWidgetHeader } from './widget-header';
import { WidgetJurisdictionHeader } from './widget-jurisdiction-header';

export type VepWidgetViewProps = {
  selectedState: Option<Jurisdiction>;
  selectedStateData: VepApiStateData | null;
  setDisplayState: (stateCode: State) => void;
  showHeader: boolean;
  hideWidgetSelector?: boolean;
};

const VepWidgetView: React.FC<VepWidgetViewProps> = ({
  selectedState,
  selectedStateData,
  setDisplayState,
  showHeader,
  hideWidgetSelector,
}) => {
  const {
    importantDatesAndDeadlines,
    registrationOptions,
    checkRegistrationUrl,
    electionInfo,
    pollingLocationLookup,
    voteByMailInfo,
    idRequirementsHtml,
  } = selectedStateData ?? {
    importantDatesAndDeadlines: null,
    registrationOptions: {
      online: null,
      mail: { copyHtml: null, url: null, urlCtaText: null },
      inPerson: null,
    },
    checkRegistrationUrl: { en: null, es: null },
    electionInfo: null,
    pollingLocationLookup: {
      iwvLookupUrl: null,
      sosLookupUrl: null,
    },
    voteByMailInfo: null,
    idRequirementsHtml: null,
  };

  const currentLocale = React.useContext(LocaleContext);

  return (
    <div lang={currentLocale} className="widget-voter-education">
      {showHeader && <VepWidgetHeader />}
      {/* We hide the widget header if it is being used in the VepBackup component since it uses its own header */}
      {!hideWidgetSelector && (
        <WidgetJurisdictionHeader
          selectedState={selectedState}
          setDisplayState={setDisplayState}
        />
      )}
      <WidgetDisplayExpandable
        importantDatesAndDeadlines={importantDatesAndDeadlines}
        voterRegistrationInfo={registrationOptions}
        checkRegistrationUrl={checkRegistrationUrl}
        electionInfo={electionInfo}
        pollingLocationLookup={pollingLocationLookup}
        voteByMailInfo={voteByMailInfo}
        idRequirements={idRequirementsHtml}
      />
    </div>
  );
};

export default VepWidgetView;
