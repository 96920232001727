// Libraries
import moment from 'moment';

import {
  ElectionInfo,
  VISVotingLocationsSuccess,
  SiteType,
  VotingLocation,
  Option,
  Jurisdiction,
} from '@dnc/baseline';

import { DefaultLocationType } from '../../../services/url-helper';

const SITE_TYPES = {
  DROPOFF: 'dropoffLocations' as 'dropoffLocations',
  EARLY_VOTE: 'earlyVoteLocations' as 'earlyVoteLocations',
  ELECTION_DAY: 'electionDayLocations' as 'electionDayLocations',
};

const SiteTypes: SiteType[] = Object.values(SITE_TYPES);

const DISPLAY_ORDER = {
  DEFAULT: [SITE_TYPES.EARLY_VOTE, SITE_TYPES.DROPOFF, SITE_TYPES.ELECTION_DAY],
  DROPOFF_FIRST: [
    SITE_TYPES.DROPOFF,
    SITE_TYPES.EARLY_VOTE,
    SITE_TYPES.ELECTION_DAY,
  ],
  EDAY: [SITE_TYPES.ELECTION_DAY, SITE_TYPES.DROPOFF],
};

const DROPOFF_FIRST_STATES = ['OR', 'WA'];

/**
 * getDisplayOrder returns the order that election locations appear on /locate/results.
 * defaultLocationType takes priority over the other locations.
 */
function getDisplayOrder(
  isEday: boolean,
  jurisdiction: Option<Jurisdiction>,
  defaultLocationType: Option<DefaultLocationType>
) {
  if (defaultLocationType) {
    if (defaultLocationType === 'electionDay') {
      return DISPLAY_ORDER.EDAY;
    } else if (defaultLocationType === 'dropoff') {
      return DISPLAY_ORDER.DROPOFF_FIRST;
    } else {
      return DISPLAY_ORDER.DEFAULT;
    }
  } else {
    if (isEday) {
      return DISPLAY_ORDER.EDAY;
    } else if (jurisdiction && DROPOFF_FIRST_STATES.includes(jurisdiction)) {
      return DISPLAY_ORDER.DROPOFF_FIRST;
    } else {
      return DISPLAY_ORDER.DEFAULT;
    }
  }
}

const BASE_MAP_URL = 'https://www.google.com/maps/';

function locationToCityStateString(location: VotingLocation): string {
  const { city, stateCode, zip } = location;
  const cityState = `${city}, ${stateCode}`;

  return zip ? `${cityState} ${zip}` : cityState;
}

function locationToAddressString(location: VotingLocation): string {
  const cityStateZip = locationToCityStateString(location);
  return `${location.addressLine1}, ${cityStateZip}`;
}

function getMapURLForLocation(location: VotingLocation): string {
  const address = locationToAddressString(location) || '';
  return `${BASE_MAP_URL}?q=${encodeURIComponent(address)}`;
}

function getMapURLForDirections(
  origin: VotingLocation,
  destination: VotingLocation
): string {
  const startAddress = locationToAddressString(origin) || '';
  const endAddress = locationToAddressString(destination) || '';
  return `${BASE_MAP_URL}?saddr=${encodeURIComponent(
    startAddress
  )}&daddr=${encodeURIComponent(endAddress)}`;
}

// Exclude early-vote-only locations (both in person
// and dropoff) if early voting is not enabled or if
// election day has arrived.
function filterLocationsList(
  locationsList: VotingLocation[],
  electionInfo: ElectionInfo,
  today: moment.Moment
) {
  const { earlyVoting, electionDay } = electionInfo;

  const isBeforeElection = today.isBefore(electionDay, 'd');
  const includeEarlyVoting =
    isBeforeElection &&
    earlyVoting.allowed &&
    today.isSameOrBefore(earlyVoting.endDate, 'd');
  return locationsList.filter((loc: VotingLocation) => {
    switch (loc.siteType) {
      case SITE_TYPES.DROPOFF: {
        return (
          includeEarlyVoting ||
          loc.openElectionDay ||
          (isBeforeElection && loc.openEarlyVoting)
        );
      }
      case SITE_TYPES.EARLY_VOTE:
        return includeEarlyVoting;
      case SITE_TYPES.ELECTION_DAY:
      default:
        return true;
    }
  });
}

function isElectionDay(
  electionDay: moment.Moment,
  today: moment.Moment
): boolean {
  return today.isSameOrAfter(electionDay, 'day');
}

function getEligibleSiteTypes(
  pollingData: VISVotingLocationsSuccess,
  isEday: boolean,
  jurisdiction: Option<Jurisdiction>,
  defaultLocationType?: Option<DefaultLocationType>
): SiteType[] {
  const eligibleSiteTypes = getDisplayOrder(
    isEday,
    jurisdiction,
    defaultLocationType
  );
  return eligibleSiteTypes.filter(
    (siteType: SiteType) => pollingData[siteType].length > 0
  );
}

export {
  DISPLAY_ORDER,
  isElectionDay,
  filterLocationsList,
  getEligibleSiteTypes,
  getMapURLForDirections,
  getMapURLForLocation,
  locationToCityStateString,
  SiteTypes,
  SITE_TYPES,
};
