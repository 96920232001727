import * as React from 'react';

import { FormattedMessage } from 'react-intl';

/**
 * This component contains headings for the polling location lookup UI and is
 * used in two places:
 *   1) the main IWV site
 *   2) the IWV voter education widget
 *
 * Because the widget is publicly released for developer use, the CSS
 * classnames here are namespaced in order to be unique when incorporated into
 * third-party websites.
 */
export const VepWidgetHeader: React.FC<{}> = () => (
  <h1 className="widget-voter-education__optional-header">
    <FormattedMessage
      defaultMessage="Learn more about voting"
      id="votingInfo.widget.header"
    />
  </h1>
);
