import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import { Jurisdiction, Option } from '@dnc/baseline';

import { JurisdictionSelect } from './action-network-capture/jurisdiction-select';

type StateConfirmationProps = {
  jurisdiction: Option<Jurisdiction>;
  updateJurisdiction: (jurisdiction: Jurisdiction) => void;
};

/**
 * This UI is only a backup for allowing a user to pick or confirm their state,
 * if the geolocation API call happens to fail.
 */
const StateConfirmation: React.FC<StateConfirmationProps> = ({
  jurisdiction,
  updateJurisdiction,
}) => (
  <div className="state-confirmation">
    <h1>
      <FormattedMessage
        defaultMessage="Where do you live?"
        id="stateConfirmation.header"
        tagName="span"
      />
    </h1>
    <p>
      <FormattedMessage
        defaultMessage="Different states vote in different ways. We'll help you understand how to vote in your state."
        id="stateConfirmation.subheader"
        tagName="span"
      />
    </p>
    <br />
    <JurisdictionSelect
      name="jurisdiction"
      className="select"
      value={jurisdiction || ''}
      onChange={(e) => {
        // The purpose of the following line is to pre-fetch the state's info.
        updateJurisdiction(e.currentTarget.value as Jurisdiction);
      }}
    />
  </div>
);

export { StateConfirmation };
