import isEqual from 'lodash/isEqual';
import React from 'react';
import { useParams } from 'react-router-dom';

import { Jurisdictions } from '../../baseline/data';
import { StringWithLeadingSlash } from '../../services/url-helper';
import {
  GaCampaignQueryParameters,
  PartnerCampaignQueryParameters,
} from '../../services/utils';

import { NavigateWithSearch } from './NavigateWithSearch';

/**
 * Helper function to enforce GA param keys. These appear in Google Analytics.
 */
function gaP(params: GaCampaignQueryParameters): GaCampaignQueryParameters {
  return params;
}

/**
 * Helper function to enforce our partner parameter keys. These are sent to
 * Action Network and Mobile Commons when we get the user’s contact information
 * during the registration flow.
 *
 * @see FormService
 */
function partnerP(
  params: PartnerCampaignQueryParameters
): PartnerCampaignQueryParameters {
  return params;
}

/**
 * @see REDIRECTS
 */
type RedirectInfo = {
  to: string;
  params?: { [k: string]: string };
};

/**
 * Map of redirects. Keys should all be lowercase.
 */
export const REDIRECTS: { [token: string]: RedirectInfo } = {};

/**
 * Helper function for populating {@link REDIRECTS}.
 *
 * Automatically lowercases the token when adding to {@link REDIRECTS}.
 *
 * Will throw an error if you try and add a redirect that already exists (but to
 * a different location). Pass `override: true` in the options parameter to
 * suppress.
 */
function addR(
  token: string,
  to: StringWithLeadingSlash,
  params: { [k: string]: string } = {},
  options: { override?: boolean } = {}
) {
  const key = token.toLowerCase();

  if (
    REDIRECTS[key] &&
    !options.override &&
    // Don’t error if the to/params match, since that comes up when states don’t
    // have spaces in their names and we’re adding the default redirects.
    (to !== REDIRECTS[key]!.to || !isEqual(params, REDIRECTS[key]!.params))
  ) {
    throw new Error(`Redirect already exists: ${key}`);
  }

  REDIRECTS[key] = { to, params };
}

// Add states to the redirects.
//
// We redirect the 2-letter code, the name of the state without spaces, with
// spaces turned to -s, and with spaces turned to _, all to the home page with
// the state pre-selected.
//
// We redirect e.g. "MAvotes" to the voting info page for that state.
for (const [jurisdiction, name] of Object.entries(Jurisdictions.allStringsEn)) {
  addR(jurisdiction, '/', { state: jurisdiction });
  addR(name.replaceAll(' ', ''), '/', { state: jurisdiction });
  addR(name.replaceAll(' ', '-'), '/', { state: jurisdiction });
  addR(name.replaceAll(' ', '_'), '/', { state: jurisdiction });
  addR(`${jurisdiction}votes`, `/votinginfo/${jurisdiction}`);
}

// This redirect is for a NV mailer, initiated late Jan. 2022
//
// TODO(fiona): Should probably delete this, since it is overwriting our default
// handling of Nevada.
addR(
  'nevada',
  '/',
  {
    state: 'NV',
    ...gaP({
      utm_source: 'DSCC',
      utm_medium: 'mailer',
      utm_campaign: 'NV2022',
    }),
    ...partnerP({
      partner: 'DSCC',
      partner_campaign: 'NV',
      partner_content: 'mailer',
    }),
  },
  { override: true }
);

// This redirect is for an AZ mailer - late May 2022
addR('voteaz', '/', {
  state: 'AZ',
  ...gaP({
    utm_source: 'DSCC',
    utm_medium: 'mailer',
    utm_campaign: 'AZ2022',
  }),
  ...partnerP({
    partner: 'DSCC',
    partner_campaign: 'AZ',
    partner_content: 'mailer',
  }),
});

// AZ Register Redirect - late August 2022
addR('registeraz', '/register', {
  state: 'AZ',
  ...gaP({
    utm_source: 'hnco',
    utm_medium: 'mail',
    utm_campaign: '2022azvoteforward',
  }),
  ...partnerP({
    partner: 'hnco',
    partner_campaign: '2022azvoteforward',
    partner_content: 'mail',
  }),
});

// AZ Make A Plan: Register Redirect - late August 2022
addR('azplan', '/', {
  state: 'AZ',
  ...gaP({
    utm_source: 'ccaz',
    utm_medium: 'lit',
    utm_campaign: '2022ccaz_lit_iwv_gotv',
  }),
  ...partnerP({
    partner: 'ccaz',
    partner_campaign: '2022ccaz_lit_iwv_gotv',
    partner_medium: 'lit',
  }),
});

// AZ Mark Kelly Redirect - late August 2022
addR('MarkKelly', '/', {
  state: 'AZ',
  ...gaP({
    utm_source: 'spaz',
    utm_medium: 'mail',
    utm_campaign: 'e_f2f_iwv_do_dvc_ve_20220826_bg_az_na_na_na',
  }),
  ...partnerP({
    partner: 'spaz',
    partner_campaign: 'e_f2f_iwv_do_dvc_ve_20220826_bg_az_na_na_na',
    partner_content: 'MCKO',
    partner_medium: 'mail',
  }),
});

// College redirects from 9/19/22
addR('pennstate', '/register', {
  state: 'PA',
  ...gaP({
    utm_source: 'dnc_mobilization',
    utm_medium: 'ads',
    utm_campaign: 'ads_dvc_p_20220917_bg_pa_pennstate',
  }),
  ...partnerP({
    partner: 'dnc_mobilization',
    partner_campaign: 'ads_dvc_p_20220917_bg_pa_pennstate',
    partner_medium: 'ads',
  }),
});

addR('drexel', '/register', {
  state: 'PA',
  ...gaP({
    utm_source: 'dnc_mobilization',
    utm_medium: 'ads',
    utm_campaign: 'ads_dvc_p_20220917_bg_pa_drexel',
  }),
  ...partnerP({
    partner: 'dnc_mobilization',
    partner_campaign: 'ads_dvc_p_20220917_bg_pa_drexel',
    partner_medium: 'ads',
  }),
});

addR('temple', '/register', {
  state: 'PA',
  ...gaP({
    utm_source: 'dnc_mobilization',
    utm_medium: 'ads',
    utm_campaign: 'ads_dvc_p_20220917_bg_pa_temple',
  }),
  ...partnerP({
    partner: 'dnc_mobilization',
    partner_campaign: 'ads_dvc_p_20220917_bg_pa_temple',
    partner_medium: 'ads',
  }),
});

// MI redirect - 10/3/22
addR('MIstudent', '/register', {
  state: 'MI',
  ...gaP({
    utm_source: 'dnc_political',
    utm_medium: 'friend_to_friend',
    utm_campaign: 'vr_iwv_tt_dvc_p_20220930_nbg_nm_b1_na_na',
  }),
  ...partnerP({
    partner: 'dnc_political',
    partner_campaign: 'vr_iwv_tt_dvc_p_20220930_nbg_nm_b1_na_na',
    partner_medium: 'friend_to_friend',
  }),
});

// MI redirects - 10/13/22

addR('MSU', '/register', {
  state: 'MI',
  ...gaP({
    utm_source: 'ccmi',
    utm_medium: 'tabling',
    utm_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_msu',
  }),
  ...partnerP({
    partner: 'ccmi',
    partner_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_msu',
    partner_medium: 'tabling',
  }),
});

addR('Umich', '/register', {
  state: 'MI',
  ...gaP({
    utm_source: 'ccmi',
    utm_medium: 'tabling',
    utm_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_umich',
  }),
  ...partnerP({
    partner: 'ccmi',
    partner_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_umich',
    partner_medium: 'tabling',
  }),
});

addR('grandvalley', '/register', {
  state: 'MI',
  ...gaP({
    utm_source: 'ccmi',
    utm_medium: 'tabling',
    utm_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_gvalley',
  }),
  ...partnerP({
    partner: 'ccmi',
    partner_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_gvalley',
    partner_medium: 'tabling',
  }),
});

addR('waynestate', '/register', {
  state: 'MI',
  ...gaP({
    utm_source: 'ccmi',
    utm_medium: 'tabling',
    utm_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_wstate',
  }),
  ...partnerP({
    partner: 'ccmi',
    partner_campaign: 'cc_2022_tb_iwv_dvc_reg_mi_campus_wstate',
    partner_medium: 'tabling',
  }),
});

// “lovers&friends” QR code / T-shirt
//
// May 2024
addR('lovers&friends', '/register', {
  ...gaP({
    utm_source: 'dnc_voter_protection',
    utm_medium: 'event',
    utm_campaign: 'e_na_iwv_vp_dvc_vr_20240503_co_natl_na_na_na',
  }),
  ...partnerP({
    partner: 'dnc_voter_protection',
    partner_campaign: 'e_na_iwv_vp_dvc_vr_20240503_co_natl_na_na_na',
    partner_medium: 'event',
  }),
});

// BET Awards - 6/30/24
addR('betawards', '/register', {
  ...gaP({
    utm_source: 'dnc_voter_protection',
    utm_medium: 'tabling',
    utm_campaign: 'em_an_iwv_do_dvc_ve_20240423_vr_us_na_na_na',
  }),
  ...partnerP({
    partner: 'dnc_voter_protection',
    partner_campaign: 'em_an_iwv_do_dvc_ve_20240423_vr_us_na_na_na',
    partner_medium: 'tabling',
  }),
});

// B6 - 8/9/24
addR('B6', '/', {
  ...gaP({
    utm_source: 'dnc_coalitions',
    utm_medium: 'email',
    utm_campaign: '2024_b6moccrockett',
  }),
  ...partnerP({
    partner: 'dnc_coalitions',
    partner_campaign: '2024_b6moccrockett',
    partner_medium: 'email',
  }),
});

// Carter - 8/12/24
addR('carter', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_medium: 'social',
    utm_campaign: '2024_p44_CGcontent',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_campaign: '2024_p44_CGcontent',
    partner_medium: 'social',
  }),
});

// I've Had It - 8/27/24
addR('ivehadit', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_medium: 'social',
    utm_campaign: '2024_p44_AJcontent',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_medium: 'social',
    partner_campaign: '2024_p44_AJcontent',
  }),
});

// Carlos - 8/27/24
addR('carlos', '/', {
  lang: 'es',
  ...gaP({
    utm_source: 'dnc',
    utm_medium: 'social',
    utm_campaign: '2024_DNCC_CEcontent',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_campaign: '2024_DNCC_CEcontent',
    partner_medium: 'social',
  }),
});

// Matt - 8/27/24
addR('matt', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_medium: 'social',
    utm_campaign: '2024_p44_MFcontent',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_campaign: '2024_p44_MFcontent',
    partner_medium: 'social',
  }),
});

// Obama - 8/27/24
addR('obama', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_medium: 'social',
    utm_campaign: '2024_p44_socialcontent',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_campaign: '2024_p44_socialcontent',
    partner_medium: 'social',
  }),
});

// Button - 9/2/24
addR('button', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_campaign: '2024_DNC_Button',
    utm_medium: 'friend_to_friend',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_campaign: '2024_DNC_Button',
    partner_medium: 'friend_to_friend',
  }),
});

// Latino - 9/3/24
addR('latino', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_campaign: '2024_DNC_Button_es',
    utm_medium: 'friend_to_friend',
  }),
  ...partnerP({
    partner: 'dnc',
    partner_campaign: '2024_DNC_Button_es',
    partner_medium: 'friend_to_friend',
  }),
});

// VMF - 9/16/24
addR('VMF', '/register', {
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: '2024_vmftrainingwebinar',
    utm_medium: 'friend_to_friend',
  }),
  ...partnerP({
    partner: 'hfp',
    partner_campaign: '2024_vmftrainingwebinar',
    partner_medium: 'friend_to_friend',
  }),
});

// HLN - 9/23/24
addR('HLN', '/', {
  ...gaP({
    utm_source: 'dnc',
    utm_campaign: '2024_hln_conference',
    utm_medium: 'tbl',
  }),
});

// GA Students - 10/4/24
addR('gastudents', '/register', {
  state: 'GA',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR',
    utm_medium: 'p2p_sms',
  }),
});

// GA State University - 10/4/24
addR('gsu', '/register', {
  state: 'GA',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'GSU',
    utm_medium: 'p2p_sms',
  }),
});

// University of Georgia - 10/4/24
addR('uga', '/register', {
  state: 'GA',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'UGA',
    utm_medium: 'p2p_sms',
  }),
});

// Arizona State University - 10/4/24
addR('asu', '/register', {
  state: 'AZ',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR-asu',
    utm_medium: 'p2p_sms',
  }),
});

// Northern AZ University - 10/4/24
addR('nau', '/register', {
  state: 'AZ',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR-nau',
    utm_medium: 'p2p_sms',
  }),
});

// University of Arizona - 10/4/24
addR('uaz', '/register', {
  state: 'AZ',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR-uaz',
    utm_medium: 'p2p_sms',
  }),
});

// AZ students - 10/4/24
addR('azstudents', '/register', {
  state: 'AZ',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR',
    utm_medium: 'p2p_sms',
  }),
});

// NV students - 10/4/24
addR('nvstudents', '/register', {
  state: 'NV',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR-nvstudents',
    utm_medium: 'p2p_sms',
  }),
});

// PA students - 10/4/24
addR('pastudents', '/register', {
  state: 'PA',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR-pastudents',
    utm_medium: 'p2p_sms',
  }),
});

// NE students - 10/4/24
addR('nestudents', '/register', {
  state: 'NE',
  ...gaP({
    utm_source: 'hfp',
    utm_campaign: 'VR-nestudents',
    utm_medium: 'p2p_sms',
  }),
});

// LGBTQ (Out for Harris) - 10/10/24
addR('lgbtq', '/', {
  ...gaP({
    utm_source: 'HFP',
    utm_campaign: '2024LGBTQ',
    utm_medium: 'em',
  }),
});

// LGBTQ Social (Out for Harris) - 10/10/24
addR('lgbtqsocial', '/', {
  ...gaP({
    utm_source: 'HFP',
    utm_campaign: '2024LGBTQ',
    utm_medium: 'social',
  }),
});

// LGBTQ Peer (Out for Harris) - 10/10/24
addR('lgbtqpeer', '/', {
  ...gaP({
    utm_source: 'HFP',
    utm_campaign: '2024LGBTQ',
    utm_medium: 'p2p_sms',
  }),
});

// Voyavotar Latino GOTV - 10/18/24
addR('LatinoGOTV', '/', {
  lang: 'es',
  ...gaP({
    utm_source: 'DNC',
    utm_campaign: '2024LatinoGOTV',
    utm_medium: 'social',
  }),
});

// Creators and Gamers - 10/22/24
addR('creators', '/', {
  ...gaP({
    utm_source: 'HFP',
    utm_campaign: '2024_creators',
    utm_medium: 'prtn',
  }),
});

// Creators and Gamers - 10/22/24
addR('hoopitup', '/', {
  ...gaP({
    utm_source: 'HFP',
    utm_campaign: 'hoopitup',
    utm_medium: 'prtn',
  }),
});

// Creators and Gamers - 10/22/24
addR('fortnite', '/', {
  ...gaP({
    utm_source: 'HFP',
    utm_campaign: 'fortnite',
    utm_medium: 'prtn',
  }),
});

/**
 * Catch-all component for redirecting both useful default URLs (like state
 * names) as well as URLs that programs have requested to exist.
 */
const IwvRedirect: React.FunctionComponent<{}> = () => {
  const { token } = useParams<'token'>();

  const redirectInfo = token && REDIRECTS[token.toLowerCase()];

  if (redirectInfo) {
    return (
      <NavigateWithSearch to={redirectInfo.to} params={redirectInfo.params} />
    );
  } else {
    // Catch-all. Goes to home.
    return <NavigateWithSearch to="/" />;
  }
};

export default IwvRedirect;
