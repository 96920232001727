import * as React from 'react';

import { ActiveLocale, LocaleToLanguageName } from '../baseline/utils';

type LocaleSelectorProps = {
  displayLocale: ActiveLocale;
  onClick: () => void;
};

export const LocaleSelector: React.FC<LocaleSelectorProps> = ({
  displayLocale,
  onClick,
}) => (
  <button
    className="locale-selector"
    onClick={onClick}
    type="button"
    lang={displayLocale}
  >
    {LocaleToLanguageName[displayLocale]}
  </button>
);
