/**
 * Shared component for handling link behavior and click tracking
 *
 * `analyticsKey`: sent to GoogleAnalytics when link is clicked
 *
 * `linkOptions`: Includes external link icon and attempts to open URL in new tab
 *                when linkOptions.target === _blank
 *
 * `beforeRedirect`: use callback to trigger action before redirecting
 */

import * as React from 'react';
import { Link } from 'react-router-dom';

import { attempt } from '@dnc/baseline';

import Check from '../public/check.svg';
import ExternalLinkIcon from '../public/external-link.svg';

import { useAnalytics } from './AnalyticsProvider';

export type ActionButtonType =
  | 'request-ballot'
  | 'locate'
  | 'custom-button-a'
  | 'custom-button-b'
  | 'confirm'
  | 'register'
  | 'in-person-info'
  | 'voter-education'

  // Only referenced by the unused registration contact capture flow
  | 'sent_sos'
  | 'sent_nvra';

export type ActionButtonProps = {
  enabled?: boolean;
  buttonClass?: string;
  children: React.ReactNode;
  url: string;
  type: ActionButtonType;
  linkOptions?: {
    rel?: string;
    target?: string;
  };
  checkmark?: boolean;
  beforeRedirect?: () => void;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  buttonClass = 'button--secondary',
  checkmark = false,
  children,
  enabled,
  url,
  type,
  linkOptions,
  beforeRedirect,
}) => {
  const analytics = useAnalytics();
  const isExternalLink = linkOptions?.target === '_blank';

  const onClick = () => {
    if (beforeRedirect) {
      beforeRedirect();
    }

    analytics.recordConversion({
      type: 'ActionOptionsClick',
      analyticsKey: type,
    });

    attempt(
      analytics.clicked({
        category: 'FrontDoor',
        target: type,
        isExternal: isExternalLink,
      })
    );
  };

  if (!enabled) {
    return null;
  }

  return (
    <Link
      to={url}
      className={`action-item ${buttonClass}`}
      onClick={onClick}
      rel={linkOptions?.rel || ''}
      target={linkOptions?.target || '_self'}
    >
      {checkmark && (
        <div className="check-mark">
          <Check />
        </div>
      )}

      {children}

      {isExternalLink && <ExternalLinkIcon className="external-link-icon" />}
    </Link>
  );
};

export { ActionButton };
