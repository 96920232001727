import * as React from 'react';
import {
  LoaderFunctionArgs,
  RouteObject,
  useLoaderData,
} from 'react-router-dom';

type Awaitable<T> = T | PromiseLike<T>;

/**
 * Type for the {@link RouteObject} returned from `lazy` functions. It’s special
 * because it’s not allowed to have a `route` parameter, and must define one of
 * the render properties.
 *
 * react-router doesn’t export this directly, so we get to it via return types.
 */
export type LazyRouteObject = Awaited<
  ReturnType<NonNullable<RouteObject['lazy']>>
>;

/**
 * Short for “renderer route.” (Abbreviated name so that this scans well in the
 * routes list.)
 *
 * Wraps React Router’s {@link RouteObject} definition to add type checking to
 * the loader pattern.
 *
 * This takes an object that matches {@link RouteObject} except instead of
 * `element` it has a `renderer` function. We use TypeScript to ensure that the
 * argument to the `renderer` matches the awaited type of the `loader` function.
 *
 * This uses {@link LoaderDataWrapper} in order to hide the use of React
 * Router’s {@link useLoaderData}.
 *
 * Overall this ergonomically addresses the two biggest design flaws with loader
 * functions, that their return values are not provided in a type-checked way
 * and that they’re only available through a hook without any seams for testing.
 */
// This first definition is for use by `lazy` functions, which may not specify a
// `route` property and must have at least one rendering prop (in our case,
// `element`).
export function rrte<T extends object>(
  route: Omit<RouteObject, 'element' | 'loader' | 'route' | 'index'> & {
    renderer: (props: T) => React.ReactElement;
    loader: (arg: LoaderFunctionArgs) => Awaitable<T>;
  }
): LazyRouteObject;
export function rrte<T extends object>(
  route: Omit<RouteObject, 'element' | 'loader'> & {
    renderer: (props: T) => React.ReactElement;
    loader: (arg: LoaderFunctionArgs) => Awaitable<T>;
  }
): RouteObject {
  const { renderer, index, children, ...routeRest } = route;

  const out = {
    ...routeRest,
    element: <LoaderDataWrapper renderer={renderer} />,
  };

  // Slight shenanigans because RouteObject is a discriminated union. “index”
  // routes are not allowed to have children.
  return index
    ? {
        index,
        ...out,
      }
    : children
      ? {
          children,
          ...out,
        }
      : {
          ...out,
        };
}

/**
 * Typesafe wrapper around {@link useLoaderData}. Allows us to both put a type
 * on the data that comes out of `loader` as well as keep our `Page` components
 * from depending on `useLoaderData` during tests.
 */
export function LoaderDataWrapper<T>({
  renderer,
}: {
  renderer: (props: T) => React.ReactElement;
}): React.ReactElement {
  const props = useLoaderData() as T;

  return renderer(props);
}
