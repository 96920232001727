import * as qs from 'query-string';
import { Location } from 'react-router-dom';

import { Jurisdiction, Option, State, Territory } from '@dnc/baseline';

import { parseQueryString } from './utils';

export type ContentfulParams = {
  preview?: true;
  test_config?: true;
};

export type InternalSiteParams = {
  lang?: Option<string>;
  preview?: Option<string>;
  test_config?: Option<string>;
  default_location_type?: Option<DefaultLocationType>;
};

export type DefaultLocationType = 'earlyVote' | 'electionDay' | 'dropoff';

export function isDefaultLocationType(
  param: Option<string>
): param is DefaultLocationType {
  const defaultLocationTypes: DefaultLocationType[] = [
    'earlyVote',
    'electionDay',
    'dropoff',
  ];
  return defaultLocationTypes.includes(param as DefaultLocationType);
}

/**
 * Helper type for our string literals to clarify that we need a leading slash
 * on the “to” paths.
 */
export type StringWithLeadingSlash = `/${string}`;

export type VotingInfoHash =
  | '#important-dates-and-deadlines'
  | '#dates-vbm'
  | '#id-requirements'
  | '#how-to-complete-your-ballot';

/**
 * Class to manage generating URLs to our pages, including passing along
 * whatever parameters are necessary to maintain site state.
 *
 * Key parameters that get passed among routes:
 *
 * - `state`: the current jurisdiction (used everywhere but
 *   `/votinginfo/:jurisdiction`).
 * - `lang`: the locale, if overriding the host / browser default.
 *
 * Also important:
 * - `preview`: whether to use the “draft” versions of Contentful content.
 * - `test_config`: whether to load Contentful content from the testing data vs.
 *   official prod data.
 *
 * `utm_` and `content_` parameters, which are important for ad/campaign
 * tracking, are typically passed when doing “correctional” redirects (_e.g._
 * `/mail` -> `/votebymail`) but not navigation, as they are interpreted by
 * {@link AnalyticsService} / {@link AnalyticsProvider} and then kept in state.
 *
 * Why we don’t take `locale` as a parameter: The locale is determined from the
 * `lang` parameter, the host name (iwillvote.com or voyavotar.com), and
 * (eventually) the browser language preferences. We basically just need to
 * preserve either the existing override (`lang` parameter) or let the defaults
 * do their thing.
 */
export class UrlHelper {
  public static readonly JURISDICTION_PARAM = 'state';
  public static readonly LOCALE_PARAM = 'lang';
  public static readonly CONTENTFUL_PREVIEW_PARAM = 'preview';
  public static readonly CONTENTFUL_TEST_CONFIG_PARAM = 'test_config';
  public static readonly DEFAULT_LOCATION_TYPE = 'default_location_type';
  /**
   * Used for testing / dev to suppress using geo-ip lookup to determine
   * jurisdiction.
   */
  public static readonly NO_GEO_PARAM = 'no_geo';

  /**
   * Query params we take an interest in.
   *
   * The type is defined as InternalSiteParam. The values here are all `Option<string>`
   * because we take advantage of `query-string`’s behavior to not include
   * parameters with undefined values.
   * Jurisdiction is not a part of this because sometimes it’s the `state` query
   * param and sometimes it’s the `:jurisdiction` path param.
   */
  public readonly siteParams: InternalSiteParams;

  /**
   * Builds a {@link UrlHelper} from a react-router {@link Location}.
   *
   * When using this, make sure to adhere to {@link Location}’s contract that
   * `search` starts with "?" and `hash` starts with "#".
   */
  public static fromLocation(location: Location): UrlHelper {
    return new UrlHelper(parseQueryString(location.search));
  }

  /**
   * Makes a {@link UrlHelper} from the raw URL string, often found on
   * `request.url` in loaders.
   */
  public static fromRequestUrl(url: string) {
    return new UrlHelper(parseQueryString(new URL(url).search));
  }

  /**
   * Create a {@link UrlHelper} out of query parameters.
   */
  constructor(params: { [k: string]: string }) {
    this.siteParams = {
      [UrlHelper.LOCALE_PARAM]: params[UrlHelper.LOCALE_PARAM],
      [UrlHelper.CONTENTFUL_PREVIEW_PARAM]:
        params[UrlHelper.CONTENTFUL_PREVIEW_PARAM],
      [UrlHelper.CONTENTFUL_TEST_CONFIG_PARAM]:
        params[UrlHelper.CONTENTFUL_TEST_CONFIG_PARAM],
      [UrlHelper.DEFAULT_LOCATION_TYPE]: isDefaultLocationType(
        params[UrlHelper.DEFAULT_LOCATION_TYPE]
      )
        ? (params[UrlHelper.DEFAULT_LOCATION_TYPE] as DefaultLocationType)
        : undefined,
    };
  }

  /**
   * Returns a URL search string for the parameters that we want to preserve
   * across requests, along with the additional params added in.
   *
   * Note that this does not validate these params, since that’s the job of
   * whatever is reading them off the request.
   *
   * @returns Search string beginning with "?", or "" if there are no params.
   */
  private makeSiteSearchString(
    extraParams: { [key: string]: string | undefined } = {}
  ): string {
    const paramsStr = qs.stringify({
      ...this.siteParams,
      ...extraParams,
    });

    if (paramsStr) {
      return `?${paramsStr}`;
    } else {
      return '';
    }
  }

  /**
   * Returns a URL with all of our {@link siteParams}, and a `state=` param if
   * `jurisdiction` is provided.
   */
  public makeSiteUrl(
    path: StringWithLeadingSlash,
    // for convenience, since it’s used almost everywhere
    jurisdiction: Option<Jurisdiction> = undefined,
    extraParams: { [key: string]: string | undefined } = {}
  ) {
    return `${path}${this.makeSiteSearchString({
      [UrlHelper.JURISDICTION_PARAM]: jurisdiction,
      ...extraParams,
    })}`;
  }

  /**
   * Given a string for a partial URL (starting at "/"), modifies its query
   * params to include our current siteParams.
   *
   * Primarily used for decorating links in Contentful content. Does not add
   * `state` parameter, the URL is assumed to already have jurisdiction
   * information.
   */
  public addSiteParamsToUrl(partialUrl: string): string {
    // Relative to window.location.href just so we can parse.
    const url = new URL(partialUrl, window.location.href);

    const newSearch = qs.stringify({
      ...parseQueryString(url.search),
      ...this.siteParams,
    });

    if (newSearch) {
      url.search = `?${newSearch}`;
    } else {
      url.search = '';
    }

    return `${url.pathname}${url.search}${url.hash}`;
  }

  public parseContentfulParams(): ContentfulParams {
    const hasPreview =
      this.siteParams[UrlHelper.CONTENTFUL_PREVIEW_PARAM] === 'true';
    const hasConfig =
      this.siteParams[UrlHelper.CONTENTFUL_TEST_CONFIG_PARAM] === 'true';
    // if we're looking at a preview
    // of existing object
    if (hasPreview && !hasConfig) {
      return { preview: true };
    }
    // if we are looking at new config object
    if (!hasPreview && hasConfig) {
      return { test_config: true };
    }
    // if we are looking at a preview of
    // new config object
    if (hasPreview && hasConfig) {
      return { preview: true, test_config: true };
    }
    return {};
  }

  /**
   * Returns URL to our page for searching for a polling place.

   * @param USState We allow this to be undefined because the locate UI works
   * without a Jurisdiction specified upfront.
   */
  public locateURL(
    USState: Option<Jurisdiction>,
    searchParams: { [key: string]: string | undefined } = {}
  ): string {
    return this.makeSiteUrl('/locate/', USState, searchParams);
  }

  /**
   * Returns URL to our results page for polling locations.

   * @param USState We allow this to be undefined because the locate UI works
   * without a Jurisdiction specified upfront.
   */
  public locateResultsActionURL(USState: Option<Jurisdiction>): string {
    return this.makeSiteUrl('/locate/results', USState);
  }

  /**
   * Returns URL to our homepage.

   * @param USState We allow this to be undefined because the home UI works
   * without a Jurisdiction specified upfront.
   */
  public homeURL(USState: Option<Jurisdiction>): string {
    return this.makeSiteUrl('/', USState);
  }

  /**
   * Returns URL to our page for registering to vote.

   * @param USState We allow this to be undefined because the register UI works
   * without a Jurisdiction specified upfront.
   */
  public registerURL(USState: Option<Jurisdiction>): string {
    return this.makeSiteUrl('/register/', USState);
  }

  public registerLandingURL(
    USState: Option<Jurisdiction>,
    searchParams: { [key: string]: string | undefined } = {}
  ): string {
    return this.makeSiteUrl('/register/options', USState, searchParams);
  }

  public inPersonInfoURL(USState: Jurisdiction): string {
    return this.makeSiteUrl('/inperson/', USState);
  }

  public inPersonInfoLandingURL(USState: Jurisdiction): string {
    return this.makeSiteUrl('/inperson/thanks', USState);
  }

  public voterEducationStateURL(
    USState: State,
    section?: VotingInfoHash
  ): string {
    return this.makeSiteUrl(`/votinginfo/${USState}`) + (section ?? '');
  }

  public voterEducationTerritoryURL(
    territory: Territory,
    section?: VotingInfoHash
  ): string {
    return this.makeSiteUrl(`/votinginfo/${territory}/`) + (section ?? '');
  }

  public voteByMailURL(USState: Jurisdiction): string {
    return this.makeSiteUrl(`/votebymail/`, USState);
  }

  public importantDatesAndDeadlinesURL(USState: State): string {
    return this.voterEducationStateURL(
      USState,
      '#important-dates-and-deadlines'
    );
  }

  public idRequirementsURL(USState: State): string {
    return this.voterEducationStateURL(USState, '#id-requirements');
  }

  public voteByMailDeadlinesURL(USState: State): string {
    return this.voterEducationStateURL(USState, '#dates-vbm');
  }

  public howToVoteURL(USState: State): string {
    return this.voterEducationStateURL(USState, '#how-to-complete-your-ballot');
  }

  public auditURL(jurisdiction: Jurisdiction): string {
    return this.makeSiteUrl(`/audit/${jurisdiction}/`);
  }

  public nationalAuditURL(): string {
    return this.makeSiteUrl('/national-audit/');
  }

  /**
   * Given a {@link Location}, adds / removes params based on `updatedParams`
   * and returns the new {@link Location}.
   *
   * Useful for changing `state`/`lang` params or deleting ones that are
   * invalid.
   */
  public static changeLocationParams(
    location: Location,
    /**
     * New params to add. Pass a value of `undefined` to remove the param.
     */
    updatedParams: { [k: string]: string | undefined }
  ): Location {
    const params = parseQueryString(location.search);

    for (const [key, value] of Object.entries(updatedParams)) {
      if (value === undefined) {
        delete params[key];
      } else {
        params[key] = value;
      }
    }

    const newSearch = qs.stringify(params);

    return {
      ...location,
      search: newSearch ? `?${newSearch}` : '',
    };
  }

  /**
   * Given a URL string (likely from `request.url`), updates the query parameters.
   *
   * Useful for clearing out invalid query parameters.
   */
  public static changeRequestUrlParams(
    urlString: string,

    /**
     * New params to add. Pass a value of `undefined` to remove the param.
     */
    updatedParams: { [k: string]: string | undefined }
  ) {
    const url = new URL(urlString);

    const params = parseQueryString(url.search);

    for (const [key, value] of Object.entries(updatedParams)) {
      if (value === undefined) {
        delete params[key];
      } else {
        params[key] = value;
      }
    }

    const newSearch = qs.stringify(params);

    return `${url.pathname}${newSearch ? `?${newSearch}` : ''}${url.hash}`;
  }
}
