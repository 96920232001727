import React from 'react';
import { useIntl } from 'react-intl';

import { ActiveLanguageToLocale, ActiveLocale } from '../../baseline/utils';
import EnLogo from '../../public/i-will-vote-logo-we.svg';
import EsLogo from '../../public/voy-a-votar-logo.svg';

type LogoProps = {
  locale: ActiveLocale;
};

export const BannerLogo: React.FC<LogoProps> = ({ locale }) => {
  const intl = useIntl();
  const Logo = locale === ActiveLanguageToLocale['Spanish'] ? EsLogo : EnLogo;

  return (
    <Logo
      aria-label={intl.formatMessage({
        id: 'wrapper.appName',
        defaultMessage: 'I Will Vote',
      })}
      className="logo header__logo"
    />
  );
};
