import moment from 'moment';
import * as React from 'react';
import {
  Navigate,
  Outlet,
  RouteObject,
  ScrollRestoration,
} from 'react-router-dom';

import { Configuration, FALLBACK_VEP_CONFIG_OBJECT } from '@dnc/baseline';

import { Services } from '../../services/services';
import { PageWrapper as AuditPageWrapper } from '../audit/page-wrapper';
import {
  ConnectedPage,
  loadJurisdictionFromPathParams,
  loadJurisdictionFromSearchQuery,
  WithSelectedJurisdiction,
  WithJurisdiction,
  WithKnownJurisdiction,
} from '../pages/ConnectedPage';

import ErrorBoundary from './ErrorBoundary';
import IwvRedirect from './IwvRedirect';
import { NavigateWithSearch } from './NavigateWithSearch';
import { rrte } from './route-utils';

export function makeRoutes(
  configuration: Configuration,
  services: Services
): RouteObject[] {
  return [
    {
      // Adds <ScrollRestoration /> globally to all pages.
      element: (
        <>
          <ScrollRestoration />
          <Outlet />
        </>
      ),
      errorElement: <ErrorBoundary sentryService={services.sentry} />,
      children: [
        // Layout route for our standard query-based pages.
        rrte({
          loader: (arg) => loadJurisdictionFromSearchQuery(services, arg, true),
          renderer: (props) => <ConnectedPage services={services} {...props} />,

          children: [
            { path: '/', lazy: lazyActionOptionsRoute },

            // "*" because <Register> has internal routes
            //
            // TODO(fiona): Factor those out to here.
            { path: 'register/*', lazy: makeLazyRegisterRoute(services) },
            { path: 'votebymail', lazy: makeLazyMailRoute(services) },
          ],
        }),

        // This is handled separately because the jurisdiction comes from the
        // path and not query params / geo-ip.
        rrte({
          path: 'votinginfo/:jurisdiction',

          loader: (arg) => loadJurisdictionFromPathParams(services, arg),
          renderer: (props) => <ConnectedPage services={services} {...props} />,

          children: [
            {
              index: true,
              lazy: lazyVotingInfoRoute,
            },
            {
              path: ':slug',
              lazy: lazyCustomVotingInfoRoute,
            },
          ],
        }),

        // Auto-detects the jurisdiction so we
        // can link users to “the voting information part of IWV"
        // without us knowing their jurisdiction ahead of time
        rrte({
          path: '/votinginfo/',

          loader: (arg) => loadJurisdictionFromSearchQuery(services, arg),
          renderer: (props) => <ConnectedPage services={services} {...props} />,

          children: [
            {
              index: true,
              element: (
                <WithSelectedJurisdiction>
                  {({ jurisdiction }) => (
                    <Navigate to={`/votinginfo/${jurisdiction}`} />
                  )}
                </WithSelectedJurisdiction>
              ),
            },
          ],
        }),

        // "Locate" Page Layout Routes
        //
        // Separate because `<Page>` takes a different prop for the layout.
        // Passed true to loadJurisdictionFromSearchQuery() as a parameter
        // to allowContentfulFailure. This allows Contentful fetches to fail
        // without throwing an error, which lets the /locate and /results
        // pages still display and function properly even if Contentful is down.
        rrte({
          path: 'locate',

          loader: (arg) => loadJurisdictionFromSearchQuery(services, arg, true),
          renderer: (props) => (
            <ConnectedPage locateLayout services={services} {...props} />
          ),

          children: [
            { index: true, lazy: makeLazyLocateFormRoute(services) },
            { path: 'results', lazy: makeLazyLocateResultsRoute(services) },
          ],
        }),
        // public facing location status page
        // uses locateLayout to skip rendering
        // IWV logo on left side of page
        rrte({
          loader: (arg) => loadJurisdictionFromSearchQuery(services, arg),
          renderer: (props) => (
            <ConnectedPage locateLayout services={services} {...props} />
          ),
          children: [
            {
              path: 'voting-locations-status',
              lazy: makeLazyLocationStatusRoute(services),
            },
          ],
        }),
        // Public facing ActionKit form from mobz
        rrte({
          loader: (arg) => loadJurisdictionFromSearchQuery(services, arg),
          renderer: (props) => (
            <ConnectedPage locateLayout services={services} {...props} />
          ),
          children: [
            {
              path: 'culture',
              lazy: makeLazyActionKitFormRoute(),
            },
          ],
        }),

        // /inperson route is currently disabled
        { path: 'inperson', element: <NavigateWithSearch to="/" /> },
        { path: 'in-person', element: <NavigateWithSearch to="/" /> },

        // Alternate VBM routes
        { path: 'mail', element: <NavigateWithSearch to="/votebymail" /> },
        {
          path: 'vote-by-mail',
          element: <NavigateWithSearch to="/votebymail" />,
        },

        // Audit routes
        ...(configuration !== Configuration.production
          ? [
              {
                // Handles both the overall layout as well as adding
                // <LocaleProvider> and <AnalyticsProvider>.
                Component: AuditPageWrapper,
                children: [
                  {
                    path: '/audit',
                    element: <NavigateWithSearch to="/audit/AL" />,
                  },
                  // For /audit/:jurisdiction, render appropriate state audit page
                  // only when :jurisdiction is a Jurisdiction; otherwise, render AL
                  // page
                  {
                    path: '/audit/:jurisdiction',
                    lazy: async () => ({
                      Component: (
                        await import(/* webpackChunkName: "Audit" */ '../audit')
                      ).default,
                    }),
                  },
                  {
                    path: '/national-audit',
                    lazy: async () => ({
                      Component: (
                        await import(
                          /* webpackChunkName: "NationalAudit" */ '../national-audit'
                        )
                      ).default,
                    }),
                  },
                ],
              },
              { path: '/storybook', element: <StorybookRedirect /> },
              {
                path: 'widget-dev',
                children: [
                  {
                    path: 'locate',
                    lazy: async () => ({
                      Component: (
                        await import(
                          /* webpackChunkName: "WidgetDev" */ '../WidgetDev'
                        )
                      ).LocateWidgetDevPage,
                    }),
                  },
                  {
                    path: 'vep',
                    lazy: async () => ({
                      Component: (
                        await import(
                          /* webpackChunkName: "WidgetDev" */ '../WidgetDev'
                        )
                      ).VepWidgetDevPage,
                    }),
                  },
                ],
              },
            ]
          : []),
      ],
    },

    // Handles any unknown single-path requests, which may be our built-in state
    // redirects or redirects set up based on program requests.
    { path: ':token', Component: IwvRedirect },

    // Catch-all for multi-path redirects to just send them home.
    { path: '*', element: <NavigateWithSearch to="/" /> },
  ];
}

/**
 * Function to load and render the {@link ActionOptions} homepage.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout.
 */
const lazyActionOptionsRoute: RouteObject['lazy'] = async () => {
  const ActionOptions = (
    await import(/* webpackChunkName: "ActionOptions" */ '../ActionOptions')
  ).default;

  return {
    element: (
      <WithSelectedJurisdiction>
        {({ jurisdiction, jurisdictionConfig }) => (
          <ActionOptions
            jurisdiction={jurisdiction}
            activeElection={jurisdictionConfig.electionInfo}
            voterRegConfig={jurisdictionConfig.registrationConfig}
            voterEdPageConfig={jurisdictionConfig.vepConfig}
            pollingLookupConfig={jurisdictionConfig.pollingLocationLookupConfig}
            ballotRequestConfig={jurisdictionConfig.ballotRequestConfig}
            jurisdictionConfigCustomButtons={
              jurisdictionConfig.customLandingPageButtons
            }
          />
        )}
      </WithSelectedJurisdiction>
    ),
  };
};

/**
 * Function to load and render the {@link VotingInfo} VEP page.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout that uses path
 * parameters for jurisdiction.
 */
const lazyVotingInfoRoute: RouteObject['lazy'] = async () => {
  const VotingInfo = (
    await import(/* webpackChunkName: "VotingInfo" */ '../voting-info')
  ).default;

  return {
    element: (
      // Use WithKnownJurisdiction because we’re in a section where the
      // jurisdiction comes from path params.
      <WithKnownJurisdiction>
        {({ jurisdiction, jurisdictionConfig }) => (
          <VotingInfo
            jurisdiction={jurisdiction}
            hasActiveRegistrationFlow={
              jurisdictionConfig?.registrationConfig?.registrationFlowEnabled ||
              false
            }
            voterEdPageConfig={
              jurisdictionConfig?.vepConfig || FALLBACK_VEP_CONFIG_OBJECT
            }
            pollingLookupConfig={
              jurisdictionConfig?.pollingLocationLookupConfig
            }
          />
        )}
      </WithKnownJurisdiction>
    ),
  };
};

/**
 * Function to load and render custom {@link CustomVepPage} VEP pages.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout that uses path
 * parameters for jurisdiction.
 */
const lazyCustomVotingInfoRoute: RouteObject['lazy'] = async () => {
  const CustomVepPage = (
    await import(/* webpackChunkName: "VotingInfo" */ '../voting-info')
  ).CustomVepPage;

  return {
    element: (
      // Use WithKnownJurisdiction because we’re in a section where the
      // jurisdiction comes from path params.
      <WithKnownJurisdiction>
        {({ jurisdiction, jurisdictionConfig }) => (
          <CustomVepPage
            jurisdiction={jurisdiction}
            customPages={jurisdictionConfig?.customPages ?? []}
          />
        )}
      </WithKnownJurisdiction>
    ),
  };
};

/**
 * Function to load and render the {@link SearchForm} locate page.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout.
 */
const makeLazyLocateFormRoute =
  (services: Services): RouteObject['lazy'] =>
  async () => {
    // We share a chunk name with the search results
    const { SearchForm } = await import(
      /* webpackChunkName: "Locate" */ '../locate/search-form'
    );

    return {
      element: (
        <WithJurisdiction>
          {({ jurisdiction }) => (
            <SearchForm
              jurisdiction={jurisdiction}
              analyticsService={services.analytics}
            />
          )}
        </WithJurisdiction>
      ),
    };
  };

/**
 * Function to load and render the {@link SearchResults} locate page.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout.
 */
const makeLazyLocateResultsRoute =
  (services: Services): RouteObject['lazy'] =>
  async () => {
    // We share a chunk name with the search form
    const { default: SearchResults, loadLocationSearchResults } = await import(
      /* webpackChunkName: "Locate" */ '../locate/search-results'
    );

    return rrte({
      loader: (arg) => loadLocationSearchResults(services, arg),
      renderer: (props) => (
        <WithJurisdiction>
          {({ jurisdiction, jurisdictionConfig }) => (
            <SearchResults
              jurisdiction={jurisdiction}
              {...props}
              today={moment()}
              customLocateMessage={
                jurisdictionConfig?.pollingLocationLookupConfig
                  ?.locateMessage ?? null
              }
              displayEarlyAbsenteeExcuse={
                jurisdictionConfig?.pollingLocationLookupConfig
                  ?.earlyAbsenteeExcuse !== null
              }
              earlyAbsenteeExcuseCopy={
                jurisdictionConfig?.pollingLocationLookupConfig
                  ?.earlyAbsenteeExcuse?.copy ?? null
              }
              informationalNotes={
                jurisdictionConfig?.pollingLocationLookupConfig
                  ?.informationalNotes ?? null
              }
              analyticsService={services.analytics}
            />
          )}
        </WithJurisdiction>
      ),
    });
  };

/**
 * Function to load and render the {@link Register} sub-pages.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout.
 */
const makeLazyRegisterRoute =
  (services: Services): RouteObject['lazy'] =>
  async () => {
    const Register = (
      await import(/* webpackChunkName: "Register" */ '../register')
    ).default;

    return {
      element: (
        <WithSelectedJurisdiction>
          {({ jurisdiction, jurisdictionConfig }) => (
            <Register
              analyticsService={services.analytics}
              formService={services.form}
              jurisdiction={jurisdiction}
              registrationConfig={jurisdictionConfig.registrationConfig}
              datesAndDeadlines={
                jurisdictionConfig.vepConfig?.importantDatesAndDeadlines
              }
            />
          )}
        </WithSelectedJurisdiction>
      ),
    };
  };

/**
 * Function to load and render the {@link Mail} sub-pages.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout.
 */
const makeLazyMailRoute =
  (services: Services): RouteObject['lazy'] =>
  async () => {
    const Mail = (await import(/* webpackChunkName: "VoteByMail" */ '../mail'))
      .default;

    return {
      element: (
        <WithSelectedJurisdiction>
          {({ jurisdiction, jurisdictionConfig }) => (
            <Mail
              analyticsService={services.analytics}
              formService={services.form}
              jurisdiction={jurisdiction}
              ballotRequestConfig={jurisdictionConfig.ballotRequestConfig}
            />
          )}
        </WithSelectedJurisdiction>
      ),
    };
  };

/**
 * Component to handle redirecting from '/storybook' -> '/storybook/index.html'
 * because S3 won’t serve it automatically.
 *
 * This is here so we don’t have to keep remembering to add 'index.html`
 * ourselves.
 */
const StorybookRedirect: React.FunctionComponent = () => {
  React.useLayoutEffect(() => {
    window.location.replace('/storybook/index.html');
  });
  return <></>;
};

/**
 * Function to load and render the {@link VotingLocationsStatus} sub-pages.
 *
 * Expected to be rendered within a {@link ConnectedPage} layout.
 */
const makeLazyLocationStatusRoute =
  (services: Services): RouteObject['lazy'] =>
  async () => {
    const VotingLocationsStatus = (
      await import(
        /* webpackChunkName: "VotingLocationsStatus" */ '../voting-locations-status'
      )
    ).default;

    return {
      element: (
        <WithSelectedJurisdiction>
          {() => (
            <VotingLocationsStatus
              pollingPlaceService={services.pollingPlace}
            />
          )}
        </WithSelectedJurisdiction>
      ),
    };
  };

const makeLazyActionKitFormRoute = (): RouteObject['lazy'] => async () => {
  const ActionKitContactForm = (
    await import(/* webpackChunkName: "ActionKitContactForm" */ '../action-kit')
  ).default;

  return {
    element: (
      <WithSelectedJurisdiction>
        {({ jurisdiction }) => (
          <ActionKitContactForm jurisdiction={jurisdiction} />
        )}
      </WithSelectedJurisdiction>
    ),
  };
};
