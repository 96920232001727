import * as React from 'react';

import { LinkedAlertFields } from '@dnc/baseline';

import { LocalizedRichText } from './LocalizedRichText';
import { COMPRESS_ON_NARROW_CLASS_NAMES } from './utils';

type RichTextAlertBannerProps = {
  className: string;
  alert: LinkedAlertFields;
};

export const RichTextAlertBanner: React.FC<RichTextAlertBannerProps> = ({
  className,
  alert,
}) => {
  return (
    <LocalizedRichText
      richTextBlock={alert.alertMessage}
      className={`${className} ${COMPRESS_ON_NARROW_CLASS_NAMES}`}
    />
  );
};
