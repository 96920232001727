import * as Sentry from '@sentry/react';

export class SentryService {
  constructor(key: string) {
    try {
      Sentry.init({
        dsn: key,
        ignoreErrors: [
          // Compatibility error with Microsoft Outlook email link crawler
          // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
          'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
          // Compatibility error with Instagram WebView for iOS
          // https://developers.facebook.com/community/threads/320013549791141/
          "Can't find variable: _AutofillCallbackHandler",
        ],
      });
    } catch (e) {
      console.error('Could not setup Sentry');
    }
  }

  record = (error: Error | string) => {
    if (typeof error === 'string') {
      Sentry.captureException(new Error(error));
    } else {
      Sentry.captureException(error);
    }
  };
}
