import { Territory } from '../data';

// These IDs point to TerritoryConfig object
// Intentionally set all IDs to ''; the entries do not exist
export const ProductionTerritoryConfigEntries: {
  [key in Territory]: string;
} = {
  AS: '',
  GU: '',
  MP: '',
  PR: '',
  VI: '',
};

// These are TestingTerritoryConfig Entries
// TestingTerritoryConfig entries are copies of
// TerritoryConfig objects safe for testing/experimentation
// Intentionally set all IDs to ''; the entries do not exist
export const TestingTerritoryConfigEntries: {
  [key in Territory]: string;
} = {
  AS: '',
  GU: '',
  MP: '',
  PR: '',
  VI: '',
};
