import React from 'react';

/* eslint-disable import/extensions */
import { MessageFormatElement } from 'react-intl';

import arMessages from '@dnc/messages/ar.json';
import enMessages from '@dnc/messages/en.json';
import esMessages from '@dnc/messages/es.json';
import koMessages from '@dnc/messages/ko.json';
import tlMessages from '@dnc/messages/tl.json';
import viMessages from '@dnc/messages/vi.json';
import zhHansMessages from '@dnc/messages/zh-Hans.json';

// We specifically don’t use @dnc/messages because we don’t want this path to be
// re-written between dev and prod.
import { compile } from '../../messages/formats.mjs';

import { ActiveLocale } from '../baseline/utils';

export const DEFAULT_RICH_TEXT_ELEMENTS = {
  ltr: (...chunks: any[]) => <span dir="ltr">{chunks}</span>,
  rtl: (...chunks: any[]) => <span dir="rtl">{chunks}</span>,
};

/**
 * Sets language used for defaultMessage (fallback) in
 * FormattedMessage component from react-intl
 */
export const DEFAULT_LOCALE = 'en';

export const locales: {
  [lang in ActiveLocale]: {
    messages:
      | { [msgId: string]: string }
      | { [msgId: string]: MessageFormatElement[] };
  };
} = {
  ar: {
    messages: prepareMessages(arMessages),
  },
  en: {
    messages: prepareMessages(enMessages),
  },
  es: {
    messages: prepareMessages(esMessages),
  },
  ko: {
    messages: prepareMessages(koMessages),
  },
  shh: { messages: {} },
  tl: { messages: prepareMessages(tlMessages) },
  vi: {
    messages: prepareMessages(viMessages),
  },
  'zh-Hans': { messages: prepareMessages(zhHansMessages) },
};

/**
 * Converts the JSON files from the `messages` directory (which are modelled after
 * the extracted en.json file) into the `{id: "message"}` format for react-intl.
 *
 * Also handles the case where we’re in production and the webpack config has
 * pointed @dnc/messages to the compiled version of the strings.
 */
function prepareMessages(msgs: { [id: string]: any }) {
  if (
    process.env.NODE_ENV === 'production' &&
    // We don’t use the pre-compiled messages for Storybook, even when NODE_ENV
    // is production for storybook:build.
    process.env.STORYBOOK !== 'true'
  ) {
    return msgs as { [msgId: string]: MessageFormatElement[] };
  }

  return compile(msgs);
}
