import * as qs from 'query-string';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { parseQueryString } from '../../services/utils';

/**
 * Implementation of {@link Navigate} that preserves query parameters from the
 * current location.
 *
 * Very important so that ad/impression tracking query parameters are persisted
 * during any redirects that happen as the app starts up.
 *
 * (The reason this comes up is that there’s a race condition with the loading
 * of the analytics code and when the router loads and fires redirects. We need
 * to make sure any campaign-tracking params, such as facebook or snap ids, are
 * still in the location field when their code finally runs. We’ve worked around
 * this for Google Analytics by saving window.location in our index.html files,
 * but Facebook doesn’t support that.)
 *
 * Internally-added utm_ parameters — perhaps from URL redirects for mailers —
 * are handled by our AnalyticsServiceAdaptor for Google Analytics.
 */
export const NavigateWithSearch: React.FunctionComponent<
  React.ComponentProps<typeof Navigate> & {
    to: string;
    params?: { [k: string]: string };
  }
> = ({ to, params = {}, ...props }) => {
  const location = useLocation();

  const search =
    Object.keys(params).length > 0
      ? qs.stringify({ ...parseQueryString(location.search), ...params })
      : location.search;

  return <Navigate {...props} to={{ pathname: to, search }} />;
};
